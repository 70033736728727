import { useState, useEffect } from 'react';
import { type Benchmark } from '../../../../models/view/Benchmark';
import { CheckboxChangeEvent } from '@progress/kendo-react-inputs';

const useSelectedBenchmarks = (benchmarks: Benchmark[]) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  useEffect(() => {
    const allBenchmarkIds = benchmarks.map((benchmark) => benchmark.id);
    const amountOfSelectedBenchmark = selectedIds.length;

    if (amountOfSelectedBenchmark === 0) {
      setSelectAllChecked(false);
    } else if (amountOfSelectedBenchmark === allBenchmarkIds.length) {
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
    }
  }, [selectedIds, benchmarks]);

  const handleSelectAllChange = (event: CheckboxChangeEvent) => {
    const checked = event.value;
    setSelectAllChecked(checked);

    const selectedIdsInCheckbox = checked ? benchmarks.map((benchmark) => benchmark.id) : [];
    setSelectedIds(selectedIdsInCheckbox);
  };

  const handleSelectChange = (benchmarkId: number) => {
    const selectedIndex = selectedIds.indexOf(benchmarkId);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedIds, benchmarkId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedIds.slice(1));
    } else if (selectedIndex === selectedIds.length - 1) {
      newSelected = newSelected.concat(selectedIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedIds.slice(0, selectedIndex), selectedIds.slice(selectedIndex + 1));
    }
    setSelectedIds(newSelected);
  };

  return {
    selectedIds,
    selectAllChecked,
    handleSelectAllChange,
    handleSelectChange,
  };
};

export default useSelectedBenchmarks;
