import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectType } from '../../../../models/view/Project';
import { Questionnaire, QuestionnaireAdult, QuestionnaireChild } from '../../../../utils/questionnaires';
import { useProjectDetailsStore } from '../../../../store/ProjectDetailsStore';

export const useBenchmark = () => {
  const navigate = useNavigate();
  const benchmarkId = useParams().benchmarkId!!;
  const projectId = useParams().projectId!!;
  const [project, benchmarks, loading] = useProjectDetailsStore((store) => [store.project, store.benchmarks, store.loading]);
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>();

  useEffect(() => {
    if (project != null && benchmarks.length > 0) {
      const answers = benchmarks.find((item) => item.id === Number(benchmarkId))?.answers;
      let questionnaire: Questionnaire;

      if (project.type === ProjectType.Adult) {
        questionnaire = new QuestionnaireAdult();
      } else {
        questionnaire = new QuestionnaireChild();
      }

      if (answers) {
        for (let answer of answers) {
          questionnaire.setAnswerbyQuestionId(Number(answer.questionId), answer.answer);
        }
        setQuestionnaire(questionnaire);
      }
    } else {
      if (!loading) {
        navigate(`/projects/${projectId}`);
      }
    }
  }, [project, benchmarkId, benchmarks, projectId, loading, navigate]);


  return {
    benchmarkId,
    questionnaire,
  };
};
