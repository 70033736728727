import ItemDetail from "./itemDetail";
import { type ItemHeaderDetails } from "./model/ItemHeaderDetails";
import {
  DatePicker,
  DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import { formatDate_YMD_HIS } from "../../../../utils/formatDate";
import "./index.scss";

const HeaderDetails = ({
  itemsHeaderDetails,
  isQuestionnairePage,
  date,
  setDate,
  isReportSection,
}: {
  itemsHeaderDetails: ItemHeaderDetails[];
  isQuestionnairePage?: boolean;
  date?: Date;
  setDate?: (date: string) => void;
  isReportSection?: boolean;
}) => {
  const maxDateToSearch = new Date("01/01/2000");
  const currentDate = date ? date : new Date();
  const options: any = { month: "long", year: "numeric" };
  const formattedCurrentDate = currentDate.toLocaleDateString("en-US", options);

  const handleSelectedDate = (event: DatePickerChangeEvent) => {
    if (event.value && setDate !== undefined) {
      const formatedDate = formatDate_YMD_HIS(event.value);
      setDate(formatedDate);
    }
  };

  return (
    <div className={"item-detail__container"}>
      {itemsHeaderDetails.map((itemDetail, index) => (
        <div key={index}>
          <ItemDetail itemName={itemDetail.title} item={itemDetail.value} />
        </div>
      ))}

      {isQuestionnairePage && ( // Render DatePicker only if on the Questionnaire page
        <div className="date-input__container">
          <h6 className="h6__style">{"Date"}</h6>
          <div className="date-picker__container">
            <DatePicker
              className="date-picker__input"
              format={"MM/dd/yyyy"}
              min={maxDateToSearch}
              max={new Date()}
              defaultValue={currentDate}
              onChange={handleSelectedDate}
            />
          </div>
        </div>
      )}

      {isReportSection && (
        <div className="date-of-prep__detail">
          <ItemDetail
            itemName={"Date of Preparation"}
            item={formattedCurrentDate}
          />
        </div>
      )}
    </div>
  );
};

export default HeaderDetails;
