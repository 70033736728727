import { createContext } from 'react';
import { createStore } from 'zustand';
import { QuestionnaireController } from '../datasource/QuestionnaireController';
import { type QuestionnaireState } from '../models/store/QuestionnaireState';
import { type ProjectType } from '../models/view/Project';
import { type Step } from '../models/view/Step';
import { QuestionnaireScreen } from '../utils/enums';
import { formatDate_YMD_HIS } from '../utils/formatDate';
import { Benchmark } from '../models/view/Benchmark';

type QuestionnaireStore = ReturnType<typeof createQuestionnaireStore>;
export const QuestionnaireContext = createContext<QuestionnaireStore | null>(null);

export const createQuestionnaireStore = (projectType: ProjectType, projectId: number, incompleteBenchmark?: Benchmark, discardIncompleteBenchmark?: boolean) => {
  const defaultStartDate = discardIncompleteBenchmark || !incompleteBenchmark ? formatDate_YMD_HIS(new Date()) : incompleteBenchmark!!.startDate;
  const questionnaireController = new QuestionnaireController(projectType, projectId, defaultStartDate, incompleteBenchmark, discardIncompleteBenchmark);
  const firstUnansweredQuestion = questionnaireController.getFirstUnansweredQuestion()!!;
  const categoryIndexToStart = questionnaireController.getCategoryIndex(firstUnansweredQuestion.category);
  const anyQuestionWasAnswered = questionnaireController.getCategoriesOverview()[0].answeredQuestions === 0;

  return createStore<QuestionnaireState>()((set, get) => {
    return {
      steps: [{ label: 'Overview' } as Step, ...questionnaireController.getQuestionnaireSteps()],
      indexCurrentStep: anyQuestionWasAnswered ? 0 : categoryIndexToStart + 1,
      date: defaultStartDate,
      categoriesOverview: questionnaireController.getCategoriesOverview(),
      currentCategoryOverview: { ...questionnaireController.getCategoriesOverview()[categoryIndexToStart], currentQuestionOrder: questionnaireController.getQuestionOrderInCategory(firstUnansweredQuestion.id, firstUnansweredQuestion.category) },
      categoryQuestionDetails: [],
      questionnaireOverview: questionnaireController.getQuestionnaireOverview(),
      currentQuestion: firstUnansweredQuestion,
      currentScreen: discardIncompleteBenchmark || anyQuestionWasAnswered ? QuestionnaireScreen.OVERVIEW : QuestionnaireScreen.QUESTION,
      isQuestionnaireCompleted: false,
      benchmarkId: incompleteBenchmark ? incompleteBenchmark.id : 0,
      categoryDetailsList: [],
      questionnaire: questionnaireController.questionnaire,
      setDate: async (formatedDate) => {
        const benchmarkId = await questionnaireController.setStartDate(formatedDate);
        set({ date: formatedDate, benchmarkId });
      },
      setAnswer: async (answer) => {
        const currentQuestion = get().currentQuestion;
        const benchmarkId = await questionnaireController.setAnswerbyQuestionId(currentQuestion.id, answer);
        set({
          currentCategoryOverview: questionnaireController.getCurrentCategoryOverview(currentQuestion.category, currentQuestion.id),
          questionnaireOverview: questionnaireController.getQuestionnaireOverview(),
          currentQuestion: questionnaireController.getQuestionById(currentQuestion.id),
          benchmarkId: benchmarkId,
        });
      },
      handleNext: () => {
        if (get().currentScreen === QuestionnaireScreen.OVERVIEW) {
          set({
            indexCurrentStep: 1,
            currentScreen: QuestionnaireScreen.QUESTION,
            currentQuestion: questionnaireController.getFirstQuestion(),
            currentCategoryOverview: { ...questionnaireController.getCategoriesOverview()[0], currentQuestionOrder: 1 },
          });
        } else {
          const currentQuestion = get().currentQuestion;
          const nextQuestion = questionnaireController.nextQuestion(currentQuestion.id);
          if (nextQuestion === null) {
            // Questionnaire finished QuestionnaireRecap
            set({
              currentScreen: QuestionnaireScreen.QUESTIONNAIRE_RECAP,
              isQuestionnaireCompleted: true,
              categoryDetailsList: questionnaireController.getCategoryDetailsList(get().benchmarkId.toString()),
              questionnaire: questionnaireController.questionnaire,
            });
          } else {
            if (currentQuestion.category === nextQuestion.category && get().currentScreen === QuestionnaireScreen.QUESTION) {
              set({ currentQuestion: nextQuestion, currentCategoryOverview: questionnaireController.getCurrentCategoryOverview(nextQuestion.category, nextQuestion.id) });
            } else {
              // Category finished CategoryRecap
              if (get().currentScreen === QuestionnaireScreen.CATEGORY_RECAP) {
                const firstQuestionInNextCategory = questionnaireController.getFirstQuestionInNextCategory(currentQuestion.category);
                set({
                  currentQuestion: firstQuestionInNextCategory,
                  currentScreen: QuestionnaireScreen.QUESTION,
                  currentCategoryOverview: questionnaireController.getCurrentCategoryOverview(firstQuestionInNextCategory.category, firstQuestionInNextCategory.id),
                  indexCurrentStep: get().indexCurrentStep + 1,
                });
              } else {
                // from QuestionnaireScreen.QUESTION_REVIEW
                if (get().isQuestionnaireCompleted) {
                  set({ currentScreen: QuestionnaireScreen.QUESTIONNAIRE_RECAP, categoryDetailsList: questionnaireController.getCategoryDetailsList(get().benchmarkId.toString()), questionnaire: questionnaireController.questionnaire });
                } else {
                  set({
                    currentScreen: QuestionnaireScreen.CATEGORY_RECAP,
                    categoryQuestionDetails: questionnaireController.getQuestionDetailsList(currentQuestion.category)!!,
                    currentQuestion: questionnaireController.getLastQuestionbyCategory(get().currentQuestion.category),
                  });
                }
              }
            }
          }
        }
      },
      handleBack: () => {
        switch (get().currentScreen) {
          case QuestionnaireScreen.CATEGORY_RECAP:
            set({ currentScreen: QuestionnaireScreen.QUESTION, indexCurrentStep: get().indexCurrentStep, currentCategoryOverview: questionnaireController.getCurrentCategoryOverview(get().currentQuestion.category, get().currentQuestion.id) });
            break;
          case QuestionnaireScreen.QUESTIONNAIRE_RECAP:
            set({ currentScreen: QuestionnaireScreen.QUESTION });
            break;
          case QuestionnaireScreen.QUESTION:
            const currentQuestion = get().currentQuestion;
            const prevQuestion = questionnaireController.prevQuestion(currentQuestion.id);
            if (prevQuestion?.category === currentQuestion.category) {
              set({ currentQuestion: prevQuestion, currentCategoryOverview: questionnaireController.getCurrentCategoryOverview(prevQuestion.category, prevQuestion.id) });
            } else if (prevQuestion === null) {
              set({ currentScreen: QuestionnaireScreen.OVERVIEW, categoriesOverview: questionnaireController.getCategoriesOverview(), indexCurrentStep: 0 });
            } else {
              set({ currentQuestion: prevQuestion, currentScreen: QuestionnaireScreen.CATEGORY_RECAP, categoryQuestionDetails: questionnaireController.getQuestionDetailsList(prevQuestion.category)!!, indexCurrentStep: get().indexCurrentStep - 1 });
            }
            break;
          case QuestionnaireScreen.OVERVIEW:
            break;
          case QuestionnaireScreen.QUESTION_REVIEW:
            if (get().isQuestionnaireCompleted) {
              set({ currentScreen: QuestionnaireScreen.QUESTIONNAIRE_RECAP });
            } else {
              set({ currentScreen: QuestionnaireScreen.CATEGORY_RECAP });
            }
            break;
        }
      },
      handleBackCategory: () => {
        const prevCategory = questionnaireController.getPrevCategory(get().currentQuestion.category);
        if (prevCategory === null) {
          set({ currentScreen: QuestionnaireScreen.OVERVIEW, categoriesOverview: questionnaireController.getCategoriesOverview(), indexCurrentStep: 0 });
        } else {
          const lastQuestionInCategory = questionnaireController.getLastQuestionbyCategory(prevCategory);
          set({ currentQuestion: lastQuestionInCategory, currentScreen: QuestionnaireScreen.CATEGORY_RECAP, categoryQuestionDetails: questionnaireController.getQuestionDetailsList(prevCategory)!!, indexCurrentStep: get().indexCurrentStep - 1 });
        }
      },
      moveToStep: (step) => {
        if (step === 0) {
          set({ currentScreen: QuestionnaireScreen.OVERVIEW, categoriesOverview: questionnaireController.getCategoriesOverview(), indexCurrentStep: 0 });
        } else {
          const category = get().steps[step].label;
          const lastQuestionInCategory = questionnaireController.getLastQuestionbyCategory(category);
          set({ currentQuestion: lastQuestionInCategory, currentScreen: QuestionnaireScreen.CATEGORY_RECAP, categoryQuestionDetails: questionnaireController.getQuestionDetailsList(category)!!, indexCurrentStep: step });
        }
      },
      handleEditQuestion: (questionId) => {
        const questionToEdit = questionnaireController.getQuestionById(questionId);
        const updatedCurrentCategoryOverview = { ...get().currentCategoryOverview, currentQuestionOrder: questionnaireController.getQuestionOrderInCategory(questionId, get().currentCategoryOverview.category) };
        set({ currentQuestion: questionToEdit, currentScreen: QuestionnaireScreen.QUESTION_REVIEW, currentCategoryOverview: updatedCurrentCategoryOverview });
      },
    };
  });
};
