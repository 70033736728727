export enum graphColors {
  'averageBenchmark1-border' = '#DC3545',
  'averageBenchmark2-border' = '#007BFF80',
  'averageBenchmark3-border' = '#28A74580',
  'averageBenchmark4-border' = '#6610F2BF',
  'averageBenchmark5-border' = '#007BFF40',
  'averageBenchmark6-border' = '#FFC10780',
  'averageBenchmark7-border' = '#FFC107',
  'averageBenchmark8-border' = '#20C997BF',
  'averageBenchmark9-border' = '#DC354580',
  'averageBenchmark10-border' = '#003d80',
  'averageBenchmark11-border' = '#28A74580',
  'averageBenchmark12-border' = '#FFC10780',
  'averageBenchmark1' = '#DC354580',
  'averageBenchmark2' = '#007BFF40',
  'averageBenchmark3' = '#2BA74540',
  'averageBenchmark4' = '#6610F24D',
  'averageBenchmark5' = '#0DCAF04D',
  'averageBenchmark6' = '#FFC10740',
  'averageBenchmark7' = '#FFC10740',
  'averageBenchmark8' = '#58D7B14D',
  'averageBenchmark9' = '#DC354540',
  'averageBenchmark10' = '#007BFF40',
  'averageBenchmark11' = '#2BA74540',
  'averageBenchmark12' = '#FFC10740',
}

export type GraphColorKeys = keyof typeof graphColors;
