import { FC, useEffect } from "react";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";

import { type DropdownFilterProps } from "./model/DropdownFilterProps";
import "./DropdownFilter.scss";
import { ProjectType } from "../../../models/view/Project";

export const DropdownFilter: FC<DropdownFilterProps> = ({
  filter,
  onFilterChange,
}) => {
  const { id, defaultItem, items, currentItem } = filter;
  let newCurrentItemWithCYP;

  useEffect(() => {
    if (currentItem === "Child") {
      newCurrentItemWithCYP = "CYP";
    } else {
      newCurrentItemWithCYP = ProjectType.Adult;
    }
  }, [currentItem]);

  const onChange = (event: DropDownListChangeEvent) =>
    onFilterChange(event, id);

  return (
    <DropDownList
      data={items}
      defaultItem={defaultItem}
      value={newCurrentItemWithCYP}
      onChange={onChange}
      className="dropdown-element"
    />
  );
};
