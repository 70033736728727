import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { CookieKeys } from '../../../utils/enums';
import { useAuth } from '../../../store/AuthProvider';
import { getCookieValue } from '../../../utils/cookies';

export const useAuthVerify = () => {
  const { isAuthenticated, username, loginInApp } = useAuth();
  const [verificationComplete, setVerificationComplete] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const storedAccessToken = getCookieValue(CookieKeys.ACCESS_TOKEN);
    const storedUserName = getCookieValue(CookieKeys.USER_NAME);
    const storedUserId = getCookieValue(CookieKeys.USER_ID) != null ? Number(getCookieValue(CookieKeys.USER_ID)) : null;

    if (storedAccessToken && storedUserName && storedUserId) {
      loginInApp(storedUserName, storedUserId);
    }

    setVerificationComplete(true);
  }, [loginInApp]);

  useEffect(() => {
    if (verificationComplete) {
      if (!isAuthenticated && location.pathname !== '/login') {
        navigate('/login');
      } else if (isAuthenticated && location.pathname === '/login') {
        navigate('/');
      }
    }
  }, [isAuthenticated, location.pathname, navigate, verificationComplete]);

  return {
    isAuthenticated,
    locationPath: location.pathname,
    username,
  };
};
