import { Link } from 'react-router-dom';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { BenchmarksTableProps } from './model/BenchmarkTable';
import './BenchmarkTable.scss';

export const BenchmarksTable = ({ benchmarks, handleSelectChange, selectAllChecked, selectedIds, handleSelectAllChange }: BenchmarksTableProps) => {

  return (
    <Grid data={benchmarks}>
      <Column
        width={35}
        headerSelectionValue={selectAllChecked}
        headerCell={() => (
          <th>
            <Checkbox checked={selectAllChecked} onChange={handleSelectAllChange} />
          </th>
        )}
        cell={(props) => (
          <td>
            <Checkbox checked={selectedIds.indexOf(props.dataItem.id) !== -1} onChange={() => handleSelectChange(props.dataItem.id)} />
          </td>
        )}
      />

      <Column field="id" title="ID" />
      <Column field="isBaseline" title="Baseline" />
      <Column field="startDate" title="Start Date" cell={(props) => <td>{new Date(props.dataItem.startDate).toLocaleDateString()}</td>} />
      <Column field="average" title="Average" />
      <Column
        field="answers"
        title="Details"
        cell={(props) => (
          <td>
            <Link to={`questionnaires/${props.dataItem.id}`} className="benchmark-table__link">
              View Questionnaire
            </Link>
          </td>
        )}
      />
    </Grid>
  );
};
