import React, { useState } from "react";
import QuestionnaireChart from "../questionnairechart/QuestionnaireChart";
import { HeaderActionBar } from "../headeractionbar/HeaderActionBar";
import { useNavigate, useParams } from "react-router-dom";
import HeaderDetails from "../benchmarkTable/HeaderDetails";
import { useProjectDetails } from "../../routes/projectdetails/hooks/useProjectDetails";
import SendButton from "../../components/sendbutton/SendButton";
import { DialogReportContainer } from "../dialogreportcontainer/DialogReportContainer";
import useSelectedIdsStore from "../../../store/useSelectedIds";
import Footer from "../footer/Footer";
import AnswersGrid from "../answersgrid/AnswersGrid";
import ChangeAnswerButtons from "./changeanswers/ChangeAnswerButtons";
import "./CreateReportBody.scss";

const CreateReportBody = () => {
  const [showModal, setShowModal] = useState(false);
  const { itemsHeaderDetails } = useProjectDetails();
  const { selectedIdsStore } = useSelectedIdsStore();
  const navigate = useNavigate();
  const projectId = useParams().projectId;

  const actionBack = () => {
    navigate(-1);
  };
  
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  if (selectedIdsStore.length === 0) actionBack();

  return (
    <div
      className="create-report__container"
      style={{
        backgroundColor: "#f8f9fa",
        width: "100%",
        height: "100vh",
      }}
    >
      <HeaderActionBar
        actionBack={actionBack}
        sectionTitle="Report Comparisons"
        children1={
          <SendButton onClick={openModal}>Send Comparison Report</SendButton>
        }
        children2={
          showModal && (
            <DialogReportContainer
              onClose={closeModal}
              isFromReportComparison={true}
            />
          )
        }
      />
      <HeaderDetails
        itemsHeaderDetails={itemsHeaderDetails}
        isReportSection={true}
      />
      <div className="chart__container">
        <QuestionnaireChart benchmarkIds={selectedIdsStore} />
      </div>

      <ChangeAnswerButtons selectedIdsStore={selectedIdsStore} />
      {showModal && <AnswersGrid selectedIdsStore={selectedIdsStore} />}
      {/* <Footer /> */}
    </div>
  );
};

export default CreateReportBody;
