import React, { FC } from "react";
import { CategoryDetailsProps } from "./model/CategoryDetailsProps";
import HeaderCategory from "../headercategory/HeaderCategory";
import { QuestionDetails } from "../questiondetails/QuestionDetails";
import './CategoryDetails.scss'

const CategoryDetails: FC<CategoryDetailsProps> = ({
  headerProps,
  questionDetailsList,
}) => {
  return (
    <div className="category-details__container">
      <HeaderCategory {...headerProps} />
      {questionDetailsList.map((questionDetail, index) => {
       return (
        <div className={`item ${index % 2 === 0 ? 'even' : 'odd'} pdf-category-question`} key={index}>
          <QuestionDetails {...questionDetail} />
        </div>
      );
      })}
    </div>
  );
};

export default CategoryDetails;
