import { FC } from 'react';
import { ProjectsTable } from '../projectstable/ProjectsTable';
import { FilterGroup } from '../filtergroup/FilterGroup';
import { useProjectFilter } from './hooks/useProjectFilter';
import { type Project } from '../../../models/view/Project';
import './ProjectsTableManager.scss';

export const ProjectsTableManager: FC<{ projects: Project[] }> = ({ projects }) => {
  const { filters, onFilterChange, tableFilter } = useProjectFilter(projects);

  return (
    <section>
      <h2 className="title">All Projects</h2>
      <FilterGroup filters={filters} onChange={onFilterChange} />
      <ProjectsTable projects={projects} filter={tableFilter} />
    </section>
  );
};
