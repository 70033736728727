import { Button } from '@progress/kendo-react-buttons';

import { useFooterButtons } from './hooks/useFooterButtons';
import './QuestionnaireFooter.scss';

export const QuestionnaireFooter = () => {
  const { nextButtonText, isNextButtonDisabled, handleBack, handleNext, isOverviewScreen,isRecapScreen } = useFooterButtons();

  return (
    <span className="questionnaire-footer__container">

      <Button className="questionnaire-footer__button" fillMode={'outline'} themeColor={'primary'} onClick={handleBack} disabled={isOverviewScreen || isRecapScreen}>
        Previous Question
      </Button>
      
      <Button className="questionnaire-footer__button" themeColor={'primary'} onClick={handleNext} disabled={isNextButtonDisabled}>
        {nextButtonText}
      </Button>
    </span>
  );
};
