import { useNavigate } from 'react-router-dom';
import { useQuestionnaireStore } from '../../../routes/questionnaire/hooks/useQuestionnaireStore';
import { QuestionnaireScreen } from '../../../../utils/enums';
import { useEffect, useState } from 'react';

export const useFooterButtons = () => {
  const navigate = useNavigate();
  const { currentScreen, currentQuestion, handleNext: handleNextInStore, handleBack: handleBackInStore, questionnaireOverview, benchmarkId } = useQuestionnaireStore();
  let nextButtonText = '';
  // let previousQuestionText = 'Previous Question';
  let handleBack = handleBackInStore;
  let handleNext = handleNextInStore;
  const [isOverviewScreen, setIsOverviewScreen ] = useState(false)
  const [isRecapScreen, setIsRecapScreen] = useState(false)

  useEffect(() => {
    setIsOverviewScreen(currentScreen === QuestionnaireScreen.OVERVIEW);
    setIsRecapScreen(currentScreen === QuestionnaireScreen.CATEGORY_RECAP);
  }, [currentScreen]);

  switch (currentScreen) {
    case QuestionnaireScreen.OVERVIEW: {
      // previousQuestionText = 'Previous'
      nextButtonText = 'Next';
      const navigateBack = () => navigate('./..');
      handleBack = navigateBack;
      break;
    }
    case QuestionnaireScreen.QUESTION: {
      if (questionnaireOverview.answeredQuestions === questionnaireOverview.questionsAmount) {
        nextButtonText = 'Finish & review';
        break;
      } else {
        
        nextButtonText = 'Save and Next';
        break;
      }
    }
    case QuestionnaireScreen.QUESTION_REVIEW:
      nextButtonText = 'Save & Return';
      break;
    case QuestionnaireScreen.CATEGORY_RECAP: {
      // previousQuestionText = 'Previous'
      nextButtonText = 'Save and Next';
      break;
    }
    case QuestionnaireScreen.QUESTIONNAIRE_RECAP: {
      nextButtonText = 'Finish';
      const navigateToReport = () => navigate(`./../questionnaires/${benchmarkId}`);
      handleNext = navigateToReport;
      break;
    }
  }

  const isNextButtonDisabled = currentScreen === QuestionnaireScreen.QUESTION && currentQuestion.answer === undefined;

  return { nextButtonText, isNextButtonDisabled, handleBack, handleNext, isOverviewScreen, isRecapScreen };
};
