import { useCallback, useState } from "react";
import { ReportController } from "../../../../datasource/ReportController";
import { sendReportInfoType } from "../../../../models/request/CreateReportRequest";

const useSendReportByEmail = (onClose: any, isCancelled: boolean) => {
  const reportController = new ReportController();
  const [sendingError, setSendingError] = useState<string | null>(null);

  const sendEmail = useCallback(
    async (sendReportInfo: sendReportInfoType) => {
      // if (isCancelled) {
      //   onClose && onClose(); 
      //   return "cancelled";
      // }
      try {
       const response = await reportController.sendReportByEmail(sendReportInfo);
        return "success";
      } catch (error: any) {
        setSendingError(
          "Error sending email. Please verify the data entered and try again."
        );
        return "failure";
      }
    },
    [onClose, reportController]
  );

  const handleSubmit = async (
    sendReportInfo: sendReportInfoType

  ): Promise<string> => {
    return await sendEmail(sendReportInfo as sendReportInfoType);
  };

  return {
    handleSubmit,
    sendingError,
  };
};

export default useSendReportByEmail;
