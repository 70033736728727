import { useNavigate } from 'react-router-dom';
import { useProjectDetails } from '../../projectdetails/hooks/useProjectDetails';
import { useEffect, useState } from 'react';
import { ReportController } from '../../../../datasource/ReportController';
import { Report } from '../../../../models/view/Report';

export const useBenchmarkReports = () => {
  const navigate = useNavigate();
  const [benchmarksComparisonReports, setBenchmarksComparisonReports] = useState<Report[]>([]);
  const { project, loading, itemsHeaderDetails } = useProjectDetails();
  const projectId = project?.id;

  useEffect(() => {
    async function fetchData() {
      const reportController = new ReportController();
      const reports = await reportController.getReportsByProjectId(project!!.id);
      setBenchmarksComparisonReports(reports);
    }

    if (project) {
      fetchData();
    }
  }, [project]);

  const actionBack = () => navigate(`/projects/${projectId}`);

  return { loading, project, itemsHeaderDetails, benchmarksComparisonReports, actionBack };
};
