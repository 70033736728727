import { type Project } from '../models/view/Project';
import { type NewProject } from '../models/view/NewProject';
import { type ProjectRequest } from '../models/request/ProjectRequest';
import { ProjectServices } from './services/ProjectServices';

export class ProjectController {
  private projectServices = new ProjectServices();

  public async getAllProjects(): Promise<Project[]> {
    try {
      const projects = await this.projectServices.fetchProjects();
      return projects;
    } catch (error: any) {
      console.error('Error getting projects: ', error.message);
      throw error;
    }
  }

  public async createProject(newProject: NewProject): Promise<Project> {

    // Change the CYP type to 'child' type to create a Child Project
    let typeProject;
    if(newProject.type === "adult") {
      typeProject = "adult"
    } else {
      typeProject = "child" 
    }

    try {
      const projectRequest: ProjectRequest = {
        name: newProject.name,
        nhs_partner: newProject.NHSProjPartner,
        nhs_lead: newProject.NHSProjLead,
        nhs_lead_email: newProject.NHSLeadEmail,
        type: typeProject,
      };
      const createdProject = await this.projectServices.postProject(projectRequest);
      return createdProject;
    } catch (error: any) {
      console.error('Error creating project: ', error.message);
      throw error;
    }
  }
}
