import React, { useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { SvgIcon } from "@progress/kendo-react-common";
import { checkIcon } from "@progress/kendo-svg-icons";
import "../dialogreportcontainer/DialogReportContainer.scss";

const CustomTitleBarSuccess = () => {
  return <div className="dialog-title">Success</div>;
};

const ModalSuccess = ({ setOpenModalSuccess }: any) => {

  const closeModalSuccess = () => {
    setOpenModalSuccess(false);
  };

  return (
    <Dialog title={<CustomTitleBarSuccess />} closeIcon={false} width={500}>
      <DialogActionsBar>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            height: "130px",
            fontWeight: 500,
            fontSize: "20px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: "10px", color: "green" }}>
              <SvgIcon icon={checkIcon} size="medium" />
            </span>
            <span>Email sent successfully!</span>
          </div>
          <form>
            <div className="dialog__footer-container-in-success">
              <Button
                className="dialog__button dialog__button--cancel"
                themeColor={"primary"}
                onClick={closeModalSuccess}
              >
                Close
              </Button>
            </div>
          </form>
        </div>
      </DialogActionsBar>
    </Dialog>
  );
};

export default ModalSuccess;
