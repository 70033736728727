import { useEffect, useState } from 'react';
import { useBenchmark } from './useBenchmark';
import { CategoryDetailsProps } from '../../categorydetails/model/CategoryDetailsProps';
import { CategoryAverage } from '../../questionnairechart/model/QuestionnaireChartProps';

export const useBenchmarkDetails = () => {
  const [categoryDetailsList, setCategoryDetailList] = useState<CategoryDetailsProps[]>([]);
  const [categoryNames, setCategoryNames] = useState<string[]>([]);
  const [categoryAverageGroup, setCategoryAverageGroup] = useState<CategoryAverage[][]>([]);
  const [questionnaireAverage, setQuestionnaireAverage] = useState<number>(0);
  const { benchmarkId, questionnaire } = useBenchmark();

  useEffect(() => {
    if (questionnaire) {
      const categoryDetails = questionnaire.getCategoryDetailsList(benchmarkId);
      const categories = questionnaire.getCategories();
      const categoryAverageGroup = categories.map(category => ({
        categoryName: category,
        value: questionnaire.getAverageByCategory(category)
      }));

      setCategoryDetailList(categoryDetails);
      setCategoryNames(categories);
      setCategoryAverageGroup([categoryAverageGroup]);
      setQuestionnaireAverage(questionnaire.getQuestionnaireAverage());
    }
  }, [questionnaire, benchmarkId]);


  return { categoryDetailsList, benchmarkId, categoryNames, categoryAverageGroup, questionnaireAverage };
};
