import { Form, Field, FormElement, FieldRenderProps, FormRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { Error as ErrorComponet, Label } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';

import { useLoginForm } from './hooks/useLoginForm';
import './LoginForm.scss';

export const LoginForm = () => {
  const { handleClick, loginFailed, userNameValidator, passwordValidator } = useLoginForm();

  const UserNameInput = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;

    return (
      <div className="k-form-field-wrap">
        <Input {...others} labelClassName={'k-form-label'} />
        {visited && validationMessage && <ErrorComponet>{validationMessage}</ErrorComponet>}
      </div>
    );
  };

  const PasswordInput = (fieldRenderProps: FieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;

    return (
      <div className="k-form-field-wrap">
        <Input {...others} labelClassName={'k-form-label'} />
        {visited && validationMessage && <ErrorComponet>{validationMessage}</ErrorComponet>}
      </div>
    );
  };

  return (
    <Form
      onSubmit={handleClick}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          {loginFailed && (
            <div className="login__login-failed-error-container flex-row-center">
              <ErrorComponet>Incorrect username or password, please try again</ErrorComponet>
            </div>
          )}
          <FieldWrapper>
            <Label className="login-form__label">Username</Label>
            <div className="k-form-field-wrap">
              <Field name={'userName'} maxLength={50} component={UserNameInput} validator={userNameValidator} className="login-form__input" />
            </div>
          </FieldWrapper>
          <FieldWrapper>
            <Label className="login-form__label">Password</Label>
            <div className="k-form-field-wrap">
              <Field name={'password'} maxLength={50} component={PasswordInput} validator={passwordValidator} type="password" className="login-form__input" />
            </div>
          </FieldWrapper>
          <Button className="login-form__button" type={'submit'} disabled={!formRenderProps.allowSubmit} themeColor={'primary'} size={'large'}>
            Log In
          </Button>
        </FormElement>
      )}
    />
  );
};
