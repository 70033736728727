import React, { FC, useState } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { IncompleteBenchmarkModalProps } from './model/IncompleteBenchmarkProps';
import ConfirmDiscardModal from './confirmDiscardBenchmark/ConfirmDiscardBenchmark';
import { useProjectDetailsStore } from '../../../store/ProjectDetailsStore';
import '../dialogcontainer/DialogContainer.scss';
import './IncompleteBenchmarkModal.scss';
import { useNavigate } from 'react-router-dom';

const CustomTitleBar = () => {
  return <div className="incomplete-benchmark__title">You have an incomplete Questionnaire</div>;
};

const IncompleteBenchmarkModal: FC<IncompleteBenchmarkModalProps> = ({ benchmarkDetails, onClose, showDiscardButton }) => {
  const [setDiscardIncompleteBenchmark] = useProjectDetailsStore((store) => [store.setDiscardIncompleteBenchmark]);
  const navigate = useNavigate();
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const backgroundColor1 = '#E9ECEF';
  const backgroundColor2 = '#FFFFFF';

  const toggleDiscardModal = () => {
    setOpenDiscardModal(!openDiscardModal);
  };

  const discardAndStartNew = () => {
    setDiscardIncompleteBenchmark(true);
    navigate('./questionnaire');
  };

  const completeQuestionnaire = () => {
    setDiscardIncompleteBenchmark(false)
    navigate('./questionnaire');
  };

  const handleClose = () => {
    setOpenDiscardModal(!openDiscardModal);
  };

  return (
    <div>
      <Dialog title={<CustomTitleBar />} onClose={onClose}>
        {benchmarkDetails && (
          <div className="incomplete-benchmark-modal__container">
            {benchmarkDetails.map((item, index) => (
              <div
                key={index}
                className="benchmark-modal-item"
                style={{
                  backgroundColor: index % 2 === 0 ? backgroundColor1 : backgroundColor2,
                }}
              >
                <div className="benchmark-modal-title">{item.title}</div>
                <div className="benchmark-modal-detail">{item.value}</div>
              </div>
            ))}
          </div>
        )}
        <DialogActionsBar>
          <div className="dialog__footer-container">
            <Button className="dialog__button dialog__button--cancel" themeColor={'primary'} onClick={onClose}>
              Cancel
            </Button>
            {showDiscardButton && (
              <Button themeColor={'primary'} onClick={toggleDiscardModal} className="discard-and-create__button">
                DISCARD AND CREATE NEW
              </Button>
            )}
            {openDiscardModal && <ConfirmDiscardModal onClose={handleClose} discardAndStartNew={discardAndStartNew} />}
            <Button themeColor={showDiscardButton ? "secondary" : "primary"} onClick={completeQuestionnaire}>
              Complete Benchmark
            </Button>
          </div>
        </DialogActionsBar>
      </Dialog>
    </div>
  );
};

export default IncompleteBenchmarkModal;
