import { CreateReportRequest, NewReport, sendReportInfoType } from "../models/request/CreateReportRequest";
import { Report } from "../models/view/Report";
import { ReportServices } from "./services/ReportServices";

export class ReportController {
  private reportServices = new ReportServices();

  public async getReportsByProjectId(projectId: number): Promise<Report[]> {
    try {
      const reports = await this.reportServices.fetchReports(projectId);
      return reports;
    } catch (error: any) {
      throw error;
    }
  }

  public async generateReport(reportInfo: NewReport): Promise<void> {
    try {
      const ReportRequest: CreateReportRequest  = {
        project_id: reportInfo.projectId,
        benchmark1_id: reportInfo.benchmarkId1,
        benchmark2_id: reportInfo.benchmarkId2,
      };
  
      const report = await this.reportServices.saveReport(ReportRequest);
      return report;
      
    } catch (error: any) {
      throw error;
    }
  }

  public async sendReportByEmail(sendReportInfo: sendReportInfoType): Promise<void> {

    try {
      const ReportRequest: sendReportInfoType  = {
        pdfFile: sendReportInfo.pdfFile,
        emails: sendReportInfo.emails,
        benchmarks_id: sendReportInfo.benchmarks_id,
      };

      await this.reportServices.sendReportByEmail(ReportRequest);
    } catch (error: any) {
      throw error;
    }
  }
}