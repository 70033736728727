import { MyError } from './MyError';
import { deleteCookie } from './cookies';
import { CookieKeys } from './enums';

export const handleUnauthorizedError = async (error: MyError) => {
  // Check if the error is an HTTP response error with status code 401
  if (error.response && error.response.status === 401) {
    // User not authorized. Cleaning cookies; the user will be redirected to /login.
    // It happens in src\ui\mainlayout\hooks\useAuthVerify.ts
    deleteCookie(CookieKeys.ACCESS_TOKEN);
    deleteCookie(CookieKeys.USER_NAME);
  }
};
