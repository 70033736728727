import { FC } from 'react';
import CategoryDetails from '../categorydetails/CategoryDetails';
import { QuestionsReportProps } from './model/QuestionsReportProps';
import './QuestionsReport.scss';

export const QuestionsReport: FC<QuestionsReportProps> = ({ categoryDetailsList }) => {
  return (
    <div className="questions-report__container">
      {categoryDetailsList.map((categoryDetailsProps, index) => {
        return <CategoryDetails key={index} {...categoryDetailsProps} />;
      })}
    </div>
  );
};
