import {
  CreateReportRequest,
  sendReportInfoType,
} from "../../models/request/CreateReportRequest";
import { ReportResponse } from "../../models/response/ReportResponse";
import { Report } from "../../models/view/Report";
import { MyError } from "../../utils/MyError";
import { getCookieValue } from "../../utils/cookies";
import { CookieKeys } from "../../utils/enums";
import { formatDate } from "../../utils/formatDate";

export class ReportServices {
  private baseUrl: string = process.env.REACT_APP_BASE_URL || "";

  private async getBearerToken(): Promise<string> {
    const bearerToken = `Bearer ${getCookieValue(CookieKeys.ACCESS_TOKEN)}`;
    return bearerToken;
  }

  public async fetchReports(projectId: number): Promise<Report[]> {
    const bearerToken = await this.getBearerToken();

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: bearerToken,
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(
      `${this.baseUrl}/report/reports/${projectId}`,
      requestOptions
    );

    if (response.ok) {
      const reportsResponse: ReportResponse[] = await response.json();
      const reports: Report[] = reportsResponse.map((reportResponse) => ({
        date: formatDate(reportResponse.created_at),
        benchmarkId1: reportResponse.benchmark1_id,
        benchmarkId2: reportResponse.benchmark2_id,
      }));

      return reports;
    } else {
      const error = new MyError(
        `Error: ${response.status} - ${response.statusText}`
      );
      error.response = response;
      throw error;
    }
  }

  public async saveReport(reportInfo: CreateReportRequest): Promise<any> {
    const bearerToken = await this.getBearerToken();

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: bearerToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reportInfo),
    };
    const response = await fetch(`${this.baseUrl}/report/save`, requestOptions);

    if (!response.ok) {
      const error = new MyError(
        `Error: ${response.status} - ${response.statusText}`
      );
      error.response = response;
      throw error;
    } else {
      return await response.json();
    }
  }


  public async sendReportByEmail(sendReportInfo: sendReportInfoType): Promise<void> {
    const bearerToken = await this.getBearerToken();

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: bearerToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pdfFile: sendReportInfo.pdfFile,
        emails: sendReportInfo.emails,
        benchmarks_id: sendReportInfo.benchmarks_id,
      }),
    };
  
    try {
      const response = await fetch(`${this.baseUrl}/report/send_multiple`, requestOptions);
      if (!response.ok) {
        const error = new MyError(
          `Error: ${response.status} - ${response.statusText}`
        );
        error.response = response;
        throw error;
      }
    } catch (error: any) {
      throw error;
    }
  }
  
}
