import { Loader } from "@progress/kendo-react-indicators";

import { ProjectCardsManager } from "../../components/projectcardsmanager/ProjectCardsManager";
import { ProjectsTableManager } from "../../components/projectstablemanager/ProjectsTableManager";
import { useProjects } from "./hooks/useProjects";
import { useProjectDetailsStore } from "../../../store/ProjectDetailsStore";
import { useEffect } from "react";
import "./Dashboard.scss";

export const Dashboard = () => {
  const { projects, loading } = useProjects();
  const [setIncompleteBenchmark] = useProjectDetailsStore((store) => [
    store.setIncompleteBenchmark,
  ]);
  // clear incomplete benchmark from previous visited project
  useEffect(() => setIncompleteBenchmark(), [setIncompleteBenchmark]);

  return (
    <div>
      <div className="dashboard">
        {loading ? (
          <Loader className="dashboard__loader" type="converging-spinner" />
        ) : (
          <>
            <ProjectCardsManager projects={projects} />
            <ProjectsTableManager projects={projects} />
            <p className="dashboard__disclaimer">
              This non-promotional tool has been initiated and funded by Takeda
              UK Ltd
            </p>
            {/* <Footer/> */}
            <div className="jobcode_container">
              <p className="jobcode_title">Job Code</p>
              <p className="jobcode_code">{process.env.REACT_APP_JOB_CODE}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
