import { useEffect, useState } from "react";
import { useProjectDetails } from "../../../routes/projectdetails/hooks/useProjectDetails";
import { ProjectType } from "../../../../models/view/Project";
import {
  Questionnaire,
  QuestionnaireAdult,
  QuestionnaireChild,
} from "../../../../utils/questionnaires";
import { CategoryAverage } from "../model/QuestionnaireChartProps";
import { DateColor } from "../benchmarkcolorsgrid/BenchmarksColorsGridProps";
import { GraphColorKeys, graphColors } from "../../../../utils/radarGraph";
import { formatDate } from "../../../../utils/formatDate";

const useBenchmarksDetails = (benchmarkIds: number[]) => {
  const [categoryNames, setCategoryNames] = useState<string[]>([]);
  const [categoryAverageGroup, setCategoryAverageGroup] = useState<
    CategoryAverage[][]
  >([]);
  const [dateColors, setDateColors] = useState<DateColor[]>([]);
  const { benchmarks, project } = useProjectDetails();

  useEffect(() => {
    if (benchmarkIds.length && project) {
      let questionnaire: Questionnaire;
      if (project.type === ProjectType.Adult) {
        questionnaire = new QuestionnaireAdult();
      } else {
        questionnaire = new QuestionnaireChild();
      }

      setCategoryNames(questionnaire.getCategories());
    }
  }, [benchmarkIds, project]);

  useEffect(() => {
    if (
      benchmarks.length &&
      benchmarkIds.length &&
      project &&
      categoryNames.length
    ) {
      const categoryAverageGroup: CategoryAverage[][] = [];
      const dateColors: DateColor[] = [];

      benchmarkIds.map((benchmarkId, index) => {
        const currentBenchmark = benchmarks.find(
          (benchmark) => benchmark.id === benchmarkId
        )!!;
        let questionnaire: Questionnaire;
        if (project.type === ProjectType.Adult) {
          questionnaire = new QuestionnaireAdult();
        } else {
          questionnaire = new QuestionnaireChild();
        }

        currentBenchmark?.answers.forEach((answer) => {
          questionnaire.setAnswerbyQuestionId(answer.questionId, answer.answer);
        });

        const categoryAverageList: CategoryAverage[] = categoryNames.map(
          (category) => {
            return {
              categoryName: category,
              value: questionnaire.getAverageByCategory(category),
            };
          }
        );
        categoryAverageGroup.push(categoryAverageList);

        //Get date colors
        dateColors.push({
          benchmarkDate: formatDate(currentBenchmark.startDate),
          color:
            graphColors[
              `averageBenchmark${index + 1}-border` as GraphColorKeys
            ],
        });
      });
      setCategoryAverageGroup(categoryAverageGroup);
      setDateColors(dateColors);
    }
  }, [benchmarks, benchmarkIds, project, categoryNames]);

  return { dateColors, nameCategories: categoryNames, categoryAverageGroup };
};

export default useBenchmarksDetails;
