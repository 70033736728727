import { BenchmarkRequest, PutBenchmarkRequest } from '../models/request/BenchmarkRequest';
import { Benchmark } from '../models/view/Benchmark';
import { CategoryOverview } from '../models/view/CategoryOverview';
import { ProjectType } from '../models/view/Project';
import { type Question } from '../models/view/Question';
import { QuestionnaireOverview } from '../models/view/QuestionnaireOverview';
import { type Step } from '../models/view/Step';
import { QuestionDetailsProps } from '../ui/components/questiondetails/model/QuestionDetailsProps';
import { changeLastCharacter } from '../utils/formatDate';
import { Questionnaire, QuestionnaireAdult, QuestionnaireChild } from '../utils/questionnaires';
import { BenchmarkServices } from './services/BenchmarkServices';

export class QuestionnaireController {
  private benchmarkServices = new BenchmarkServices();
  private benchmarkId: number = -1;
  private startDate: string;
  protected type: ProjectType;
  protected projectId: number;
  public questionnaire: Questionnaire;

  constructor(projectType: ProjectType, projectId: number, startDate: string, incompleteBenchmark?: Benchmark, discardIncompleteBenchmark?: boolean) {
    this.type = projectType;
    this.projectId = projectId;
    this.startDate = startDate;
    if (projectType === ProjectType.Adult) {
      this.questionnaire = new QuestionnaireAdult();
    } else {
      this.questionnaire = new QuestionnaireChild();
    }
    if (incompleteBenchmark) {
      this.benchmarkId = incompleteBenchmark.id;
      if (!discardIncompleteBenchmark) {
        incompleteBenchmark.answers.forEach((answer) => {
          this.questionnaire.setAnswerbyQuestionId(answer.questionId, answer.answer);
        });
      }
    }
  }

  public getQuestionnaireSteps(): Step[] {
    return this.questionnaire.getCategories().map((category) => ({ label: category } as Step));
  }

  public getFirstQuestion(): Question {
    return this.questionnaire.getFirstQuestion();
  }

  public async setAnswerbyQuestionId(questionId: number, answer: Question['answer']): Promise<number> {
    this.questionnaire.setAnswerbyQuestionId(questionId, answer);
    // create or update Benchmark in DB
    if (this.benchmarkId !== -1) {
      // update benchmark
      // When update a benchmark is necessary set a diferent value in start_date than last time
      // changeLastCharacter changes the value of seconds
      // it's due to unexpected backend behavior
      this.startDate = changeLastCharacter(this.startDate)
      const updatedBenchmark: PutBenchmarkRequest = { id: this.benchmarkId, project_id: this.projectId, answers: this.questionnaire.getAnswers(), start_date: this.startDate };
      this.benchmarkServices.putBenchmark(updatedBenchmark);
    } else {
      // create benchmark
      const benchmark: BenchmarkRequest = { project_id: this.projectId, answers: this.questionnaire.getAnswers(), start_date: this.startDate };
      const createdBenchmark = await this.benchmarkServices.postBenchmark(benchmark);
      this.benchmarkId = createdBenchmark.benchmarkId;
    }

    return this.benchmarkId;
  }

  public getFirstUnansweredQuestion(): Question | null {
    return this.questionnaire.getFirstUnansweredQuestion();
  }

  public getCategoryIndex(category: string): number {
    return this.questionnaire.getCategoryIndex(category);
  }

  public async setStartDate(startDate: string) {
    this.startDate = startDate;
    // update Benchmark in DB
    if (this.benchmarkId !== -1) {
      // update benchmark
      // When update a benchmark is necessary set a diferent value in start_date than last time
      // changeLastCharacter changes the value of seconds
      // it's due to unexpected backend behavior
      this.startDate = changeLastCharacter(this.startDate)
      const updatedBenchmark: PutBenchmarkRequest = { id: this.benchmarkId, project_id: this.projectId, answers: this.questionnaire.getAnswers(), start_date: this.startDate };
      this.benchmarkServices.putBenchmark(updatedBenchmark);
    }

    return this.benchmarkId
  }

  public nextQuestion(currentQuestionId: number): Question | null {
    return this.questionnaire.getNextQuestion(currentQuestionId);
  }

  public prevQuestion(currentQuestionId: number): Question | null {
    return this.questionnaire.getPreviousQuestion(currentQuestionId);
  }

  public getPrevCategory(category: string): string | null {
    return this.questionnaire.getPrevCategory(category);
  }

  public getCategoriesOverview(): CategoryOverview[] {
    return this.questionnaire.getCategoriesOverview();
  }

  public getQuestionnaireOverview(): QuestionnaireOverview {
    return this.questionnaire.getQuestionnaireOverview();
  }

  public getCurrentCategoryOverview(category: string, questionId: number): CategoryOverview {
    return this.questionnaire.getCurrentCategoryOverview(category, questionId);
  }

  public getQuestionById(questionId: number): Question {
    return this.questionnaire.getQuestionById(questionId)!!;
  }

  public getFirstQuestionInNextCategory(currentCategory: string): Question {
    return this.questionnaire.getFirstQuestionInNextCategory(currentCategory);
  }

  public getQuestionOrderInCategory(questionId: number, category: string): number {
    return this.questionnaire.getQuestionOrderInCategory(questionId, category);
  }

  public getQuestionDetailsList(category: string): QuestionDetailsProps[] | null {
    return this.questionnaire.getQuestionDetailsList(category);
  }

  public getLastQuestionbyCategory(category: string) {
    return this.questionnaire.getLastQuestionbyCategory(category);
  }

  public getCategoryDetailsList(benchmarkId: string) {
    return this.questionnaire.getCategoryDetailsList(benchmarkId);
  }
}
