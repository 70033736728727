import { useContext } from 'react';
import { useStore } from 'zustand';
import { QuestionnaireContext } from '../../../../store/QuestionnaireStore';

export const useQuestionnaireStore = () => {
  const store = useContext(QuestionnaireContext);
  if (!store) throw new Error('Missing QuestionnaireContext.Provider in the tree');
  const [
    steps,
    indexCurrentStep,
    date,
    categoriesOverview,
    categoryQuestionDetails,
    currentCategoryOverview,
    questionnaireOverview,
    currentQuestion,
    currentScreen,
    isQuestionnaireCompleted,
    benchmarkId,
    categoryDetailsList,
    questionnaire,
    setDate,
    setAnswer,
    handleNext,
    handleBack,
    handleBackCategory,
    moveToStep,
    handleEditQuestion,
  ] = useStore(store, (s) => [
    s.steps,
    s.indexCurrentStep,
    s.date,
    s.categoriesOverview,
    s.categoryQuestionDetails,
    s.currentCategoryOverview,
    s.questionnaireOverview,
    s.currentQuestion,
    s.currentScreen,
    s.isQuestionnaireCompleted,
    s.benchmarkId,
    s.categoryDetailsList,
    s.questionnaire,
    s.setDate,
    s.setAnswer,
    s.handleNext,
    s.handleBack,
    s.handleBackCategory,
    s.moveToStep,
    s.handleEditQuestion,
  ]);

  return {
    steps,
    indexCurrentStep,
    date,
    categoriesOverview,
    categoryQuestionDetails,
    currentCategoryOverview,
    questionnaireOverview,
    currentQuestion,
    currentScreen,
    isQuestionnaireCompleted,
    benchmarkId,
    categoryDetailsList,
    questionnaire,
    setDate,
    setAnswer,
    handleNext,
    handleBack,
    handleBackCategory,
    moveToStep,
    handleEditQuestion,
  };
};
