import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const handleGenerateComparisonReportPdf = async () => {
  // Get necessary HTML elements
  const headerElement = document.querySelector('.header-element__topdf') as HTMLElement | null;
  const detailElement = document.querySelector('.item-detail__container') as HTMLElement | null;
  const chartElement = document.querySelector('.chart-div__container') as HTMLElement | null;
  const benchmarkIdElement = document.querySelector('.benchmarksId-grid__container') as HTMLElement | null;
  const answersGridElements = document.querySelectorAll('.answer-grid-container');

  // const footerElement = document.querySelector('.footer__content') as HTMLElement | null;

  // Check if any necessary element not found
  if (!chartElement || !detailElement|| !answersGridElements.length || !benchmarkIdElement || !headerElement) {
    console.error('Element not found.');
    return;
  }

  // Create a new instance of jsPDF
  const pdf = new jsPDF({compress: true});
  const imgWidth = 210; // Image width in the PDF

  // Set background color and draw a rectangle for each page
  pdf.setFillColor('#F8F9FA');
  pdf.rect(0, 0, imgWidth, pdf.internal.pageSize.getHeight(), 'F');

  // Calculate available height for elements on each page
  const pageHeight = pdf.internal.pageSize.getHeight();
  const pageWidth = pdf.internal.pageSize.getWidth();
  let remainingHeight = pageHeight - 4; // Available space for elements on a page

  // Function to add a new page with padding at the end
  const addPageWithPadding = () => {
    pdf.addPage();
    pdf.setFillColor('#F8F9FA');
    pdf.rect(0, 0, pageWidth, pageHeight, 'F');
  };

  // Function to add an element to the current page
  const addElementToPage = async (element: HTMLElement) => {
    try {
      // change the scale to modify the resolution of the resulting PDF
      const elementCanvas = await html2canvas(element, { scale: 1 });
      const currentElementHeight = elementCanvas.height / (elementCanvas.width / pageWidth);

      pdf.addImage(elementCanvas, 'PNG', 2, pageHeight - remainingHeight - 2, pageWidth - 4, currentElementHeight);
      remainingHeight -= currentElementHeight;
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('Error generating PDF. Please try again.');
    }
  };

  const addElementOrPage = async (currentElement: HTMLElement, addBenchmarkIdsElement?: boolean) => {
    const currentElementCanvas = await html2canvas(currentElement);
    const currentElementHeight = currentElementCanvas.height / (currentElementCanvas.width / pageWidth);

    // Check if there is enough space on the current page to add the element
    if (currentElementHeight <= remainingHeight) {
      await addElementToPage(currentElement);
    } else {
      // If there is not enough space, add a new page
      addPageWithPadding();
      remainingHeight = pageHeight - 4;
      if (addBenchmarkIdsElement) {
        await addElementToPage(benchmarkIdElement);
      }
      await addElementToPage(currentElement);
    }
  };

  // Add elements to the PDF
  try {
    await addElementToPage(headerElement);
    await addElementToPage(detailElement);
    await addElementToPage(chartElement);
    await addElementToPage(benchmarkIdElement);

    for (let i = 0; i < answersGridElements.length; i++) {
      const currentElement = answersGridElements[i] as HTMLElement;
      const headerCategoryElement = currentElement.querySelector('.pdf-category-header') as HTMLElement;
      const questionCategoryElements = currentElement.querySelectorAll('.pdf-category-question');
      await addElementOrPage(headerCategoryElement, true);

      for (let index = 0; index < questionCategoryElements.length; index++) {
        const currentElement = questionCategoryElements[index] as HTMLElement;
        await addElementOrPage(currentElement, true);
      }
    }

    // await addElementOrPage(footerElement);

    const pdfFile: any = pdf;

    
    const pdfBase64 = pdfFile.output('datauristring').split(',')[1];

    return pdfBase64;

  } catch (error) {
    console.error('Error generating PDF:', error);
    alert('Error generating PDF. Please try again.');
  }
};
