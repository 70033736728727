import { useEffect } from "react";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { useProjectFilteringStore } from "../store/projectFiltering";
import { ProjectType, type Project } from "../../../../models/view/Project";

export const useProjectFilter = (projects: Project[]) => {
  const [tableFilter, setProjects, filters, updateFilters] = useProjectFilteringStore((store) => [store.tableFilter, store.setProjects, store.filters, store.updateFilters]);
  
  useEffect(() => {
    setProjects(projects);
  }, [projects, setProjects]);
  
  const onFilterChange = (event: DropDownListChangeEvent, filterId: string) => {

    const filter = filters.find((it) => it.id === filterId)!!;
    let updatedFilter:any;

    if (event.target.value === 'CYP') {
      updatedFilter = { ...filter, currentItem: ProjectType.Child };
    } else {
      updatedFilter = { ...filter, currentItem: event.target.value };
    }
    updateFilters(updatedFilter);
  };

  return { filters, onFilterChange, tableFilter }
}