import { FC } from 'react';

import { ProjectCard } from '../projectcard/ProjectCard';
import { getLatestProjects } from '../../../utils/utils';
import { type Project } from '../../../models/view/Project';
import './ProjectCardsManager.scss';

export const ProjectCardsManager: FC<{ projects: Project[] }> = ({ projects }) => {
  const lastFourProjects = getLatestProjects(projects, 4);

  return (
    <section>
      <h2 className="title">Latest Projects</h2>
      <span className="card-list__container">
        {lastFourProjects.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </span>
    </section>
  );
};
