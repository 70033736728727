import { FC } from 'react';
import { QuestionDetailsProps } from './model/QuestionDetailsProps';
import { Button } from '@progress/kendo-react-buttons';
import { pencilIcon } from '@progress/kendo-svg-icons';
import './QuestionDetails.scss';

export const QuestionDetails: FC<QuestionDetailsProps> = ({ order, question, answer, onEdit }) => {
  return (
    <div className="question-details__container flex-row-center">
      <div className="question-details__order-container">
        <div className="question-details__order-number">{order}</div>
      </div>

      <p className="question-details__question">{question}</p>
      <span className="question-details__answer">{answer}</span>
      {onEdit && (
        <Button fillMode={'outline'} themeColor={'primary'} onClick={onEdit} svgIcon={pencilIcon} className="question-details__edit-button">
          Edit
        </Button>
      )}
    </div>
  );
};
