import { useQuestionnaireStore } from '../../routes/questionnaire/hooks/useQuestionnaireStore';
import { QuestionDetails } from '../questiondetails/QuestionDetails';
import './QuestionnaireCategoryRecap.scss';

export const QuestionnaireCategoryRecap = () => {
  const { categoryQuestionDetails, handleEditQuestion } = useQuestionnaireStore();
  const questionDetailsList = categoryQuestionDetails.map((questionDetails) => {
    const handleEdit = () => handleEditQuestion(questionDetails.questionId);
    return { ...questionDetails, onEdit: handleEdit };
  });

  return (
    <div className="questionnaire-category-recap__container">
      <h2 className="questionnaire-category-recap__title">Please confirm your answers on this section</h2>
      {questionDetailsList.map((questionDetail, index) => {
        return (
          <div className={`item ${index % 2 === 0 ? 'even' : 'odd'}`} key={index} style={{ width:'100%'}}>
            <QuestionDetails key={index} {...questionDetail} />
          </div>
        );
      })}
    </div>
  );
};
