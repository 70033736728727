import { FC } from 'react';
import { ReportsTableProps } from '../createreportbody/model/ReportsTableProps';
import { Grid, GridColumn as Column, GridSelectionChangeEvent, getSelectedState } from '@progress/kendo-react-grid';
import { useNavigate } from 'react-router-dom';
import useSelectedIdsStore from '../../../store/useSelectedIds';

const ReportsTable: FC<ReportsTableProps> = ({ benchmarksComparisonReports }) => {
  const navigate = useNavigate();
  const { addSelectedIds } = useSelectedIdsStore();

  const onClick = (event: GridSelectionChangeEvent) => {
    const selectedStateBenchmark1 = getSelectedState({
      event,
      selectedState: {},
      dataItemKey: 'benchmarkId1',
    });
    const selectedStateBenchmark2 = getSelectedState({
      event,
      selectedState: {},
      dataItemKey: 'benchmarkId2',
    });
    const benchmark1 = Number(Object.keys(selectedStateBenchmark1)[0]);
    const benchmark2 = Number(Object.keys(selectedStateBenchmark2)[0]);
    addSelectedIds([benchmark1, benchmark2]);
    navigate(`../create-report/${benchmark1}`);
  };

  return (
    <div>
      <Grid data={benchmarksComparisonReports} onSelectionChange={onClick} selectable={{ enabled: true, drag: false, mode: 'single' }}>
        <Column title="Date" field="date" />
        <Column title="Questionnaire 1" field="benchmarkId1" />
        <Column title="Questionnaire 2" field="benchmarkId2" />
      </Grid>
    </div>
  );
};

export default ReportsTable;
