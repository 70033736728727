import { getCookieValue } from '../../utils/cookies';
import { capitalize } from '../../utils/utils';
import { CookieKeys } from '../../utils/enums';
import { type Project, type ProjectType } from '../../models/view/Project';
import { type ProjectResponse } from '../../models/response/ProjectResponse';
import { type ProjectRequest } from '../../models/request/ProjectRequest';
import { MyError } from '../../utils/MyError';

export class ProjectServices {
  private baseUrl: string = process.env.REACT_APP_BASE_URL || '';

  private async getBearerToken(): Promise<string> {
    const bearerToken = `Bearer ${getCookieValue(CookieKeys.ACCESS_TOKEN)}`;
    return bearerToken;
  }

  public async fetchProjects(): Promise<Project[]> {
    const bearerToken = await this.getBearerToken();

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: bearerToken,
        'Content-Type': 'application/json',
      },
    };

    const response = await fetch(`${this.baseUrl}/project/all`, requestOptions);

    if (response.ok) {
      const projectsResponse: ProjectResponse[] = await response.json();
      const projects: Project[] = projectsResponse.map((projectResponse) => ({
        id: projectResponse.id,
        name: projectResponse.name,
        takedaRepr: projectResponse.rep_email,
        NHSProjPartner: projectResponse.nhs_partner,
        NHSProjLead: projectResponse.nhs_lead,
        NHSLeadEmail: projectResponse.nhs_lead_email,
        type: capitalize(projectResponse.type) as ProjectType,
        startDate: projectResponse.start_date,
      }));

      return projects;
    } else {
      const error = new MyError(`Error: ${response.status} - ${response.statusText}`);
      error.response = response;
      throw error;
    }
  }

  public async postProject(projectRequest: ProjectRequest): Promise<Project> {
    try {
      const bearerToken = await this.getBearerToken();

      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: bearerToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(projectRequest),
      };

      const response = await fetch(`${this.baseUrl}/project/create`, requestOptions);
      if (response.ok) {
        const createdProjectResponse: ProjectResponse[] = await response.json();
        const createdProject: Project = {
          id: createdProjectResponse[0].id,
          name: createdProjectResponse[0].name,
          takedaRepr: createdProjectResponse[0].rep_email,
          NHSProjPartner: createdProjectResponse[0].nhs_partner,
          NHSProjLead: createdProjectResponse[0].nhs_lead,
          NHSLeadEmail: createdProjectResponse[0].nhs_lead_email,
          type: capitalize(createdProjectResponse[0].type) as ProjectType,
          startDate: createdProjectResponse[0].start_date,
        };

        return createdProject;
      } else {
        const error = new MyError(`Error: ${response.status} - ${response.statusText}`);
        error.response = response;
        throw error;
      }
    } catch (error: any) {
      console.error('Error posting project: ', error.message);
      throw error;
    }
  }
}