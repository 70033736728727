import { QuestionsReport } from '../questionsreport/QuestionsReport';
import { useCategoryDetails } from './hooks/useCategoryDetails';
import './QuestionnaireRecap.scss';

export const QuestionnaireRecap = () => {
  const { categoryDetailsList } = useCategoryDetails();

  return (
    <div className="questionnaire-recap__container">
      <h2 className="questionnaire-recap__title">Please confirm all your answers</h2>
      <QuestionsReport categoryDetailsList={categoryDetailsList} />
    </div>
  );
};
