import { useState } from 'react';

export const useShowDialog = () => {
  const [visible, setVisible] = useState<boolean>(false);

  const toggleDialog = () => {
    setVisible(!visible);
  };

  return {
    visible,
    toggleDialog,
  };
};
