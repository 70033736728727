import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AuthProvider } from '../store/AuthProvider';
import { MainLayout } from './mainlayout/MainLayout';
import { Dashboard } from './routes/dashboard/Dashboard';
import { ErrorPage } from './routes/ErrorPage';
import { Login } from './routes/login/Login';
import { PrivacyNotice } from './routes/privacynotice/PrivacyNotice';
import { ProjectDetails } from './routes/projectdetails/ProjectDetails';
import { Questionnaire } from './routes/questionnaire/Questionnaire';
import QuestionnaireReport from './routes/questionnaireReport/QuestionnaireReport';
import ReportsEvaluation from "./routes/reportsevaluation/ReportsEvaluation";
import CreateReport from "./routes/createreport/CreateReport";
import './App.scss';
import AnswersGrid from "./components/answersgrid/AnswersGrid";
import AnswersGridRoute from "./routes/answersgrid/AnswersGridRoute";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/privacy-notice",
        element: <PrivacyNotice />,
      },
      {
        path: "/projects/:projectId",
        children: [
          {
            index: true,
            element: <ProjectDetails/>
          },
          {
            path: "questionnaires/:benchmarkId",
            element: <QuestionnaireReport/>,
          },
          {
            path: 'questionnaire',
            element: <Questionnaire />,
          },
          {
            path: 'reports',
            element: <ReportsEvaluation/>,
          },
          {
            path: 'create-report/:benchmarkId',
            element: <CreateReport/>,
            
          },
        ],
      },
      
    ],
  },
]);

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </AuthProvider>
  );
}

export default App;
