import { FC } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardTitle,
  CardBody,
  CardActions,
  CardSubtitle,
  CardHeader,
} from "@progress/kendo-react-layout";

import { ProjectType, type Project } from "../../../models/view/Project";
import "./ProjectCard.scss";

export const ProjectCard: FC<{ project: Project }> = ({ project }) => {
  const navigate = useNavigate();
  const handleClick = () => navigate(`/projects/${project.id}`);

  return (
    <Card>
      <CardHeader className="card-header__container">
        <CardTitle>#{project.id}</CardTitle>
        <CardSubtitle>{project.name}</CardSubtitle>
      </CardHeader>
      <CardBody>
        <ul>
          <li>{project.NHSProjPartner}</li>
          <li>{project.NHSProjLead}</li>
          {project.type === ProjectType.Adult ? (
            <li>Adult Benchmark</li>
          ) : (
            <li>CYP Benchmark</li>
          )}
          
          <li>{project.startDate}</li>
        </ul>
      </CardBody>
      <CardActions className="card__button">
        <button onClick={handleClick}>Open</button>
      </CardActions>
    </Card>
  );
};
