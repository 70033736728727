import { BenchmarkRequest, PutBenchmarkRequest } from '../../models/request/BenchmarkRequest';
import { BenchmarkResponse } from '../../models/response/BenchmarkResponse';
import { Benchmark } from '../../models/view/Benchmark';
import { MyError } from '../../utils/MyError';
import { getCookieValue } from '../../utils/cookies';
import { CookieKeys } from '../../utils/enums';

export class BenchmarkServices {
  private baseUrl: string = process.env.REACT_APP_BASE_URL || '';

  private async getBearerToken(): Promise<string> {
    const bearerToken = `Bearer ${getCookieValue(CookieKeys.ACCESS_TOKEN)}`;
    return bearerToken;
  }

  public async fetchBenchmarksByProjectId(projectId: number): Promise<Benchmark[]> {
    const bearerToken = await this.getBearerToken();

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: bearerToken,
        'Content-Type': 'application/json',
      },
    };

    const response = await fetch(`${this.baseUrl}/benchmark/all/${projectId}`, requestOptions);

    if (response.ok) {
      const benchmarksResponse: BenchmarkResponse[] = await response.json();
      const benchmarks: Benchmark[] = benchmarksResponse.map((benchmarkResponse) => ({
        id: benchmarkResponse.id,
        projectId: benchmarkResponse.project_id,
        ownerId: benchmarkResponse.owner_id,
        answers: JSON.parse(benchmarkResponse.answers).map((ans: { question_id: string; answer: string }) => ({
          questionId: Number(ans.question_id),
          answer: [0, 1, 2, 3, 4, 5, 6].includes(Number(ans.answer)) ? Number(ans.answer) : 0,
        })),
        startDate: benchmarkResponse.start_date,
        average: benchmarkResponse.average,
        isBaseline: benchmarkResponse.is_baseline === 1 ? 'Y' : 'N',
        createdAt: benchmarkResponse.created_at,
        updatedAt: benchmarkResponse.updated_at,
      }));

      return benchmarks;
    } else {
      const error = new MyError(`Error: ${response.status} - ${response.statusText}`);
      error.response = response;
      throw error;
    }
  }

  public async postBenchmark(benchmarkRequest: BenchmarkRequest): Promise<{ benchmarkId: number }> {
    try {
      const bearerToken = await this.getBearerToken();

      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: bearerToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(benchmarkRequest),
      };

      const response = await fetch(`${this.baseUrl}/benchmark/create`, requestOptions);

      if (response.ok) {
        const createdBenchmarkResponse: BenchmarkResponse[] = await response.json();
        const benchmarkId: number = createdBenchmarkResponse[0].id;
        return { benchmarkId };
      } else {
        const error = new MyError(`Error: ${response.status} - ${response.statusText}`);
        error.response = response;
        throw error;
      }
    } catch (error: any) {
      console.error('Error posting benchmark: ', error.message);
      throw error;
    }
  }

  public async putBenchmark(putBenchmarkRequest: PutBenchmarkRequest): Promise<void> {
    try {
      const bearerToken = await this.getBearerToken();

      const requestOptions = {
        method: 'PUT',
        headers: {
          Authorization: bearerToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(putBenchmarkRequest),
      };

      const response = await fetch(`${this.baseUrl}/benchmark/update`, requestOptions);

      if (response.ok) {
        // Nothing to do
        // const updatedBenchmarkResponse: BenchmarkResponse[] = await response.json();
        // const benchmarkId: number = updatedBenchmarkResponse[0].id;
        // return { benchmarkId };
      } else {
        const error = new MyError(`Error: ${response.status} - ${response.statusText}`);
        error.response = response;
        throw error;
      }
    } catch (error: any) {
      console.error('Error puting benchmark: ', error.message);
      throw error;
    }
  }
}
