import { AnswerRequest } from '../models/request/BenchmarkRequest';
import { CategoryOverview } from '../models/view/CategoryOverview';
import { AnswerType, Question } from '../models/view/Question';
import { QuestionnaireOverview } from '../models/view/QuestionnaireOverview';
import { CategoryDetailsProps } from '../ui/components/categorydetails/model/CategoryDetailsProps';
import { HeaderCategoryProps } from '../ui/components/headercategory/model/HeaderCategoryProps';
import { QuestionDetailsProps } from '../ui/components/questiondetails/model/QuestionDetailsProps';
import { ADULT_QUESTIONNAIRE } from './questionnaireAdult';
import { CATEGORIES } from './questionnaireCategories';
import { CHILD_QUESTIONNAIRE } from './questionnaireChild';

export class Questionnaire {
  protected questions: Question[];

  constructor(data: Question[]) {
    this.questions = data;
  }

  isCompleted(): boolean {
    const unansweredQuestion = this.questions.find((question) => question.answer === undefined)
    return unansweredQuestion === undefined;
  }

  getFirstQuestion(): Question {
    return this.questions[0];
  }

  getFirstUnansweredQuestion(): Question | null {
    return this.questions.find((question) => question.answer === undefined) || null;
  }

  getCategoryIndex(category: string): number {
    return this.getCategories().findIndex(it => it === category)
  }

  getQuestionById(questionId: number): Question | undefined {
    return this.questions.find((question) => question.id === questionId);
  }

  getFirstQuestionInNextCategory(currentCategory: string): Question {
    const nextCategory = this.getCategories()[this.getCategories().indexOf(currentCategory) + 1];
    return this.questions.find((question) => question.category === nextCategory)!!;
  }

  getCategories(): string[] {
    const uniqueCategories = new Set(this.questions.map((question) => question.category));
    return Array.from(uniqueCategories);
  }

  setAnswerbyQuestionId(questionId: number, answer: Question['answer']): void {
    const question = this.getQuestionById(questionId);

    if (question) {
      question.answer = answer;
    }
  }

  setAnswersArraybyQuestionId(questionId: number, answer: Question['answer']): void {
    const question = this.getQuestionById(questionId);

    if (question) {
      if (question.answerReport?.length) {
        question.answerReport = [ ...question.answerReport, answer as AnswerType ]
      } else {
        question.answerReport = [ answer as AnswerType ]
      }
    }
  }


  getAnswers(): AnswerRequest[] {
    return this.questions
      .filter((question) => question.answer !== undefined)
      .map((question) => {
        return { question_id: question.id, answer: question.answer } as AnswerRequest;
      });
  }

  getNextQuestion(currentQuestionId: number): Question | null {
    const currentIndex = this.questions.findIndex((question) => question.id === currentQuestionId);

    if (currentIndex + 1 < this.questions.length) {
      return this.questions[currentIndex + 1];
    } else {
      return null;
    }
  }

  getPreviousQuestion(currentQuestionId: number): Question | null {
    const currentIndex = this.questions.findIndex((question) => question.id === currentQuestionId);

    if (currentIndex - 1 >= 0) {
      return this.questions[currentIndex - 1];
    } else {
      return null;
    }
  }

  getPrevCategory(category: string): string | null {
    const categories = this.getCategories();
    const currentIndex = categories.indexOf(category);

    if (currentIndex > 0) {
      return categories[currentIndex - 1];
    } else return null;
  }

  getCategoryIndexOfLastCompletedCategory(): number {
    const categories = this.getCategories();

    let lastCompletedIndex = -1;

    for (let i = 0; i < categories.length; i++) {
      const category = categories[i];
      const questionsInCategory = this.getQuestionsByCategory(category);
      const allQuestionsAnswered = questionsInCategory.every((question) => question.answer !== undefined);

      if (allQuestionsAnswered) {
        lastCompletedIndex = i;
      } else {
        // If a category is found where not all questions are answered,
        // it means subsequent categories cannot be completed,
        // so we can break out of the loop.
        break;
      }
    }

    return lastCompletedIndex;
  }

  getCategoryDetailsList(benchmarkId: string): CategoryDetailsProps[] {
    const categoryDetailsList: CategoryDetailsProps[] = [];

    for (const category of Object.values(CATEGORIES)) {
      const categoryDetails = this.getCategoryDetails(category, benchmarkId);

      if (categoryDetails !== null) {
        categoryDetailsList.push(categoryDetails);
      }
    }

    return categoryDetailsList;
  }

  getCompareCategoryDetailsList(benchmarkId: string): CategoryDetailsProps[] {
    const categoryDetailsList: CategoryDetailsProps[] = [];

    for (const category of Object.values(CATEGORIES)) {
      const categoryDetails = this.getCompareCategoryDetails(category, benchmarkId);

      if (categoryDetails !== null) {
        categoryDetailsList.push(categoryDetails);
      }
    }

    return categoryDetailsList;
  }

  getCategoryDetails(category: string, benchmarkId: string): CategoryDetailsProps | null {
    const questionsInCategory = this.questions.filter((question) => question.category === category);

    if (questionsInCategory.length > 0) {
      const headerProps: HeaderCategoryProps = {
        benchmarkId: Number(benchmarkId),
        categoryName: category,
        average: getFormatedAverage(questionsInCategory),
      };
      
      const questionDetailsList: QuestionDetailsProps[] = questionsInCategory.map((question, index) => ({
        questionId: question.id,
        order: index + 1,
        question: question.text,
        answer: question.answer,
      }));

      return {
        headerProps: headerProps,
        questionDetailsList: questionDetailsList,
      } as CategoryDetailsProps;
    } else {
      return null;
    }
  }

  getCompareCategoryDetails(category: string, benchmarkId: string): CategoryDetailsProps | null {
    const questionsInCategory = this.questions.filter((question) => question.category === category);

    if (questionsInCategory.length > 0) {
      const headerProps: HeaderCategoryProps = {
        benchmarkId: Number(benchmarkId),
        categoryName: category,
        average: getFormatedAverage(questionsInCategory),
      };

      const questionDetailsList = questionsInCategory.map((question, index) => ({
        questionId: question.id,
        order: index + 1,
        question: question.text,
        answer: question.answerReport,
      }));

      return {
        headerProps: headerProps,
        questionDetailsList: questionDetailsList,
      } as CategoryDetailsProps;
    } else {
      return null;
    }
  }

  getQuestionDetailsList(category: string): QuestionDetailsProps[] | null {
    const questionsInCategory = this.questions.filter((question) => question.category === category);

    if (questionsInCategory.length > 0) {
      const questionDetailsList: QuestionDetailsProps[] = questionsInCategory.map((question, index) => ({
        questionId: question.id,
        order: index + 1,
        question: question.text,
        answer: question.answer,
      }));

      return questionDetailsList;
    } else {
      return null;
    }
  }

  getCategoriesOverview(): CategoryOverview[] {
    let categoriesOverview: CategoryOverview[] = [];

    const categories = this.getCategories();
    for (let category of categories) {
      const categoryOverview = { category, questionsAmount: this.getQuestionsByCategory(category).length, answeredQuestions: this.getAnsweredQuestionAmountByCategory(category) } as CategoryOverview;
      categoriesOverview.push(categoryOverview);
    }

    return categoriesOverview;
  }

  getQuestionsByCategory(category: string): Question[] {
    return this.questions.filter((question) => question.category === category);
  }

  getAnsweredQuestionAmountByCategory(category: string): number {
    const questionsInCategory = this.getQuestionsByCategory(category);
    return questionsInCategory.filter((question) => question.answer !== undefined).length;
  }

  getQuestionnaireOverview(): QuestionnaireOverview {
    return { questionsAmount: this.questions.length, answeredQuestions: this.getAnsweredQuestionsAmount() } as QuestionnaireOverview;
  }

  getAnsweredQuestionsAmount(): number {
    let answeredQuestions = 0;

    for (let category of this.getCategories()) {
      answeredQuestions += this.getAnsweredQuestionAmountByCategory(category);
    }

    return answeredQuestions;
  }

  getCurrentCategoryOverview(category: string, questionId: number): CategoryOverview {
    const questionsInCategory = this.getQuestionsByCategory(category);
    const currentQuestionOrder = this.getQuestionOrderInCategory(questionId, category);

    return {
      category,
      questionsAmount: questionsInCategory.length,
      answeredQuestions: this.getAnsweredQuestionAmountByCategory(category),
      currentQuestionOrder: currentQuestionOrder !== -1 ? currentQuestionOrder : 0,
    };
  }

  getQuestionOrderInCategory(questionId: number, category: string) {
    const questionsInCategory = this.getQuestionsByCategory(category);
    const index = questionsInCategory.findIndex((question) => question.id === questionId);

    if (index !== -1) {
      // question found in category, return order it's equal index + 1
      return index + 1;
    }

    // question not found in category
    return -1;
  }

  getLastQuestionbyCategory(category: string): Question {
    return this.getQuestionsByCategory(category).at(-1)!!;
  }

  getAverageByCategory(category: string): number {
    let average = 0;
    const questionsInCategory = this.getQuestionsByCategory(category);
    const answeredQuestions = questionsInCategory.filter((question) => question.answer);
    average = getAverage(answeredQuestions);

    return average;
  }

  getQuestionnaireAverage(): number {
    const answeredQuestions = this.questions.filter((question) => question.answer);
    return getAverage(answeredQuestions);
  }

  getQuestionsAmount(): number {
    return this.questions.length
  }
}


export class QuestionnaireAdult extends Questionnaire {
  constructor() {
    const adultQuestionnaireCopy = [...ADULT_QUESTIONNAIRE.map((question) => ({ ...question }))];
    super(adultQuestionnaireCopy);
  }

}

export class QuestionnaireChild extends Questionnaire {
  constructor() {
    const childQuestionnaireCopy = [...CHILD_QUESTIONNAIRE.map((question) => ({ ...question }))];
    super(childQuestionnaireCopy);
  }
}



const getFormatedAverage = (questions: Question[]): string => {
  const sum = questions.reduce((acc, question) => acc + Number(question.answer || 0), 0);
  const average = questions.length > 0 ? sum / questions.length : 0;
  const roundedAverage = parseFloat(average.toFixed(1));

  return `${roundedAverage}/${questions.length}`;
};

const getAverage = (questions: Question[]): number => {
  const sum = questions.reduce((acc, question) => acc + Number(question.answer || 0), 0);
  const average = questions.length > 0 ? sum / questions.length : 0;
  const roundedAverage = parseFloat(average.toFixed(2));

  return roundedAverage;
};
