import { FC } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { chevronLeftIcon } from '@progress/kendo-svg-icons';
import { Label } from '@progress/kendo-react-labels';

import { type HeaderActionBarProps } from './model/HeaderActionBarProps';
import './HeaderActionBar.scss';

export const HeaderActionBar: FC<HeaderActionBarProps> = ({ actionBack, sectionTitle, children1, children2, textBackBtn = "Back" }) => {
  return (
    <header className="header-action-bar__container">
        <div >

      <span>
        <Button fillMode={'outline'} themeColor={'primary'} svgIcon={chevronLeftIcon} onClick={actionBack}>
          {textBackBtn}
        </Button>
        <Label className="header-action-bar__title">{sectionTitle}</Label>
      </span>
        </div>
      {children1 && children1}
      {children2 && children2}
    </header>
  );
};
