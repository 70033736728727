import { FC } from 'react';
import { AnswerOption } from '../answeroption/AnswerOption';
import { type AnswerOptionGroupProps } from './model/AnswerOptionGroupProps';
import './AnswerOptionGroup.scss';

export const AnswerOptionGroup: FC<AnswerOptionGroupProps> = ({ answerOptions }) => {
  return (
    <div className="answer-option-group__container">
      {answerOptions.map((answeOption) => (
        <AnswerOption {...answeOption} />
      ))}
    </div>
  );
};
