import { FC, useEffect, useRef, useState } from 'react';
import { Chart, ChartLegend, ChartSeries, ChartSeriesItem, ChartValueAxis, ChartValueAxisItem } from '@progress/kendo-react-charts';

import { RadarChartProps } from './model/RadarChartProps';
import { BenchmarkAverage } from '../../../models/view/BenchmarkAverage';
import { GraphColorKeys, graphColors } from '../../../utils/radarGraph';

export const RadarChart: FC<RadarChartProps> = ({ nameCategories, categoryAverageGroup }) => {
  const [data, setData] = useState<BenchmarkAverage[]>([]);
  const prevChartOptionsRef = useRef<any>(null);

  useEffect(() => {
    if (nameCategories.length && categoryAverageGroup.length) {
      const newData = nameCategories.map((category) => {
        let benchmarkAverages: BenchmarkAverage = {
          categoryName: category,
        };

        categoryAverageGroup.forEach((categoryAverageList, index) => {
          const currentCategoryAverage = categoryAverageList.find((categoryAverage) => categoryAverage.categoryName === category);
          if (currentCategoryAverage) {
            benchmarkAverages[`averageBenchmark${index + 1}`] = currentCategoryAverage.value;
          }
        });
        return benchmarkAverages;
      });

      setData(newData);
    }
  }, [nameCategories, categoryAverageGroup]);

  // this function will be excecuted every time the chart tries rerender
  // So to avoid unnecessary rendering, it checks if the lastAverageValue exist
  // if lastAverageValue exists and is the same than in the last rendering, the chart won't rerender
  const handleChartRefresh = (chartOptions: any, themeOptions: any, chartInstance: any) => {
    const lastAverageValue = chartOptions.series[categoryAverageGroup.length * 2 - 1]?.data[nameCategories.length - 1]?.averageBenchmark1;
    if (data.length > 0 && lastAverageValue !== prevChartOptionsRef.current) {
      chartInstance.setOptions(chartOptions, themeOptions);
      prevChartOptionsRef.current = lastAverageValue;
    }
  };

  return (
    <Chart onRefresh={handleChartRefresh} className="radar-chart-element">
      <ChartSeries>
        {categoryAverageGroup.map((_categoryAverageList, index) => (
          <ChartSeriesItem type="radarArea" data={data} field={`averageBenchmark${index + 1}`} categoryField="categoryName" color={graphColors[`averageBenchmark${index + 1}` as GraphColorKeys]} spacing={10} />
        ))}
        {categoryAverageGroup.map((_categoryAverageList, index) => (
          <ChartSeriesItem type="radarLine" data={data} field={`averageBenchmark${index + 1}`} categoryField="categoryName" color={graphColors[`averageBenchmark${index + 1}-border` as GraphColorKeys]} width={2} />
        ))}
      </ChartSeries>
      <ChartValueAxis>
        <ChartValueAxisItem labels={{ format: 'N' }} majorUnit={1} max={6} />
      </ChartValueAxis>
      <ChartLegend position="bottom" />
    </Chart>
  );
};
