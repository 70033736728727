import { FC } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { CreateProjectForm } from '../createprojectform/CreateProjectForm';
import { type DialogContainerProps } from './model/DialogContainerProps';
import './DialogContainer.scss';

export const DialogContainer: FC<DialogContainerProps> = (props) => {
  const { title, submitText, submitAction, toggleDialog, errors, registerField, control } = props;

  const CustomTitleBar = () => {
    return <div className="dialog-title">{title}</div>;
  };

  return (
    <Dialog title={<CustomTitleBar />} closeIcon={false} width={700} height={800}>
      <form onSubmit={submitAction}>
        <div className="dialog__form-container">
          <CreateProjectForm errors={errors} registerField={registerField} control={control} />
        </div>
        <DialogActionsBar>
          <div className="dialog__footer-container">
            <Button className="dialog__button dialog__button--cancel" themeColor={'primary'} onClick={toggleDialog}>
              Cancel
            </Button>
            <Button type="submit" className="dialog__button" themeColor={'primary'}>
              {submitText}
            </Button>
          </div>
        </DialogActionsBar>
      </form>
    </Dialog>
  );
};
