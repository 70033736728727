import React from "react";
import { QuestionsReport } from "../../questionsreport/QuestionsReport";
import { useBenchmarkDetails } from "../../questionsreport/hooks/useBenchmarkDetails";
import SurveyButtons from "../surveybuttons/SurveyButtons";

const ChangeAnswerButtons = ({
  selectedIdsStore,
}: {
  selectedIdsStore: number[];
}) => {
  const { categoryDetailsList } = useBenchmarkDetails();

  return (
    <div>
      <SurveyButtons selectedIdsStore={selectedIdsStore} />
      <QuestionsReport categoryDetailsList={categoryDetailsList} />
    </div>
  );
};

export default ChangeAnswerButtons;
