import { Benchmark } from "../models/view/Benchmark";
import { BenchmarkServices } from "./services/BenchmarkServices";

export class BenchmarkController {
  private benchmarkServices = new BenchmarkServices();

  public async getBenchmarksByProjectId(projectId: number): Promise<Benchmark[]> {
    try {
      const benchmarks = await this.benchmarkServices.fetchBenchmarksByProjectId(projectId);
      return benchmarks;
    } catch (error: any) {
      throw error;
    }
  }
}