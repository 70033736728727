import { create } from 'zustand';
import { projectProperties } from '../../../../utils/projects';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { type ProjectFilteringState } from '../model/ProjectFilteringState';
import { type MyFilter } from '../model/MyFilter';
import { type Project } from '../../../../models/view/Project';

export const useProjectFilteringStore = create<ProjectFilteringState>((set) => {
  return {
    filteredProjects: [],
    tableFilter: {
      logic: 'and',
      filters: [],
    },
    filters: [
      { id: projectProperties.NHSProjPartner.id, items: [], defaultItem: projectProperties.NHSProjPartner.longTitle, currentItem: null },
      { id: projectProperties.NHSProjLead.id, items: [], defaultItem: projectProperties.NHSProjLead.longTitle, currentItem: null },
      { id: projectProperties.type.id, items: [], defaultItem: projectProperties.type.shortTitle, currentItem: null },
    ],
    setProjects: (projects) => {
      set((state) => {
        const extractFieldValues = (property: keyof Project) => {
          const values: any[] = Array.from(new Set(projects.map((project) => project[property])));

          return { fieldId: property, values };
        };

        const newFieldValues: MyFilter[] = state.filters.map((filter) => {
          const updatedItems = extractFieldValues(filter.id as keyof Project).values;
          return { ...filter, items: updatedItems, currentItem: null };
        });

        return { filteredProjects: projects, filters: newFieldValues };
      });
    },
    updateFilters: (updatedFilter: MyFilter) => {
      set((state) => {
        const updatedFilters = state.filters.map((filter) => {
          if (filter.id === updatedFilter.id) {
            return { ...filter, ...updatedFilter };
          }
          return filter;
        });

        // Check if the filter with the given id already exists
        const existingFilterIndex = state.tableFilter.filters.findIndex((filter) => (filter as FilterDescriptor).field === updatedFilter.id);
        const updatedTableFilter = { ...state.tableFilter }; // Create a copy of tableFilter
        const selectedFilter = updatedFilter.currentItem !== updatedFilter.defaultItem ? updatedFilter.currentItem : null;

        if (existingFilterIndex !== -1) {
          // If it exists, modify the existing filter or remove it
          if (selectedFilter !== null) {
            updatedTableFilter.filters[existingFilterIndex] = { field: updatedFilter.id, operator: 'eq', value: updatedFilter.currentItem };
          } else {
            // Remove filter
            updatedTableFilter.filters.splice(existingFilterIndex, 1);
          }
        } else {
          // If it doesn't exist and selectedFilter is not null, add a new filter
          if (selectedFilter !== null) {
            updatedTableFilter.filters.push({ field: updatedFilter.id, operator: 'eq', value: updatedFilter.currentItem });
          }
        }

        return { filters: updatedFilters, tableFilter: updatedTableFilter };
      });
    },
  };
});
