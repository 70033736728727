import React from "react";
import "./index.scss";

const ItemDetail = ({ itemName, item }: any) => {
  const isIPadMini = window.innerWidth <= 800;

  return (
    <div className="item__container" style={{paddingRight:"10px"}}>
      {itemName === "Job Code" ? (
        <div className="job-code__container">
          <h6 className="h6__style__job-code">{itemName}</h6>
          <h6 className="h6__style2__job-code-value">{item}</h6>
        </div>
      ) : (
        <span className={`${isIPadMini && itemName === "Date" ? "date__detail" : ""}`}>
          <h6 className="h6__style">{itemName}</h6>
          <h6 className="h6__style2">{item}</h6>
        </span>
      )}
    </div>
  );
};

export default ItemDetail;
