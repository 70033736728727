import { FC, useEffect, useState } from "react";
import { Error as ErrorComponet, Label } from "@progress/kendo-react-labels";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { Controller } from "react-hook-form";

import { projectProperties } from "../../../utils/projects";
import { type CreateProjectFormProps } from "./model/CreateProjectFormProps";
import { type MySwitchFields } from "../customswitch/model/MySwitchFields";
import { CustomSwitch } from "../customswitch/CustomSwitch";
import { getCompleteLinkByPath } from "../../../utils/utils";
import FormErrorDot from "./FormDotError";
import { useProjectsStore } from "../../../store/ProjectsStore";
import "./CreateProjectForm.scss";

export const CreateProjectForm: FC<CreateProjectFormProps> = ({
  errors,
  registerField,
  control,
}) => {
  const [projects] = useProjectsStore((store) => [
    store.projects,
    store.loading,
    store.fetchProjects,
  ]);
  const [projectNameAvailabilityError, setProjectNameAvailabilityError] =
    useState(false);
  const [validationErrors, setValidationErrors] = useState("");

  const customSwitchFields: MySwitchFields = {
    left: {
      value: "cyp",
      label: "CYP",
    },
    right: {
      value: "adult",
      label: "Adult",
    },
  };

  const isEmailInvalid =
    errors[projectProperties.NHSLeadEmail.id]?.type === "pattern";

  const privacyLink = getCompleteLinkByPath("/privacy-notice");

  const isFieldEmpty = (fieldId: any) => {
    return errors[fieldId]?.type === "required";
  };

  const isProjectNameAvailable = (name: string) => {
    return projects.every((project) => project.name !== name);
  };

  const handleProjectNameChange = (event: any) => {
    const projectName = event.target.value;
    setProjectNameAvailabilityError(!isProjectNameAvailable(projectName));
  };

  const renderLabel = (fieldId: any, labelText: any) => {
    return (
      <div className="form__label-container">
        <Label
          className={`form__label${
            isFieldEmpty(fieldId) ? " form__label--error" : ""
          }`}
        >
          {labelText}
        </Label>
        {isFieldEmpty(fieldId) && <FormErrorDot />}
        {fieldId == "name" && projectNameAvailabilityError ? (
          <ErrorComponet className="error-message-project-name">
            The name for this project is not available
          </ErrorComponet>
        ) : (
          <span> </span>
        )}
      </div>
    );
  };
  useEffect(() => {
    const updateCombinedError = () => {
      if (isEmailInvalid) {
        setValidationErrors("*The email field must be valid");
      } else if (Object.keys(errors).length > 0 && !isEmailInvalid) {
        setValidationErrors("*Required fields");
      } else {
        setValidationErrors("");
      }
    };
    updateCombinedError();
  }, [isEmailInvalid, Object.keys(errors).length]);


  return (
    <span className="form__container">
      {renderLabel(projectProperties.name.id, projectProperties.name.longTitle)}

      <Input
        {...registerField(projectProperties.name.id, { required: true })}
        className="form__input"
        labelClassName={"k-form-label"}
        onChange={handleProjectNameChange}
      />

      {renderLabel(
        projectProperties.NHSProjPartner.id,
        projectProperties.NHSProjPartner.longTitle
      )}
      <Input
        {...registerField(projectProperties.NHSProjPartner.id, {
          required: true,
        })}
        className="form__input"
        labelClassName={"k-form-label"}
      />

      {renderLabel(
        projectProperties.NHSProjLead.id,
        projectProperties.NHSProjLead.longTitle
      )}
      <Input
        {...registerField(projectProperties.NHSProjLead.id, { required: true })}
        className="form__input"
        labelClassName={"k-form-label"}
      />

      {renderLabel(
        projectProperties.NHSLeadEmail.id,
        projectProperties.NHSLeadEmail.longTitle
      )}
      <Input
        {...registerField(projectProperties.NHSLeadEmail.id, {
          required: true,
          pattern: {
            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: "The email field must be valid",
          },
        })}
        className="form__input"
        labelClassName={"k-form-label"}
      />

      <CustomSwitch
        control={control}
        fields={customSwitchFields}
        className="form__input"
      />

      <span className="form__privacy-header-container">
        <Label>Privacy Notice</Label>
        <a
          href={privacyLink}
          target="_blank"
          rel="noopener noreferrer"
          className="form__privacy-link"
        >
          <img
            src="/images/icFile.svg"
            alt="File icon"
            className="form__privacy-link-icon"
          />
          Press here to view
        </a>
      </span>
      <p className="form__privacy-text">
        By providing your Information, which is conditional upon your ticking "I
        accept", you acknowledge that you have read and understood this Notice
        and you consent to the processing of your Information for the purposes
        as explained in this Privacy Notice.
      </p>
      <Controller
        name="privacy"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <div className="form__privacy-checkbox">
            <Checkbox {...field} />
            <Label className="form__privacy-label">I accept</Label>
            {isFieldEmpty("privacy") && <FormErrorDot />}
          </div>
        )}
      />
      <div className="error-message-form__container">
        {validationErrors ? (
          <ErrorComponet className="error-message">
            {validationErrors}
          </ErrorComponet>
        ) : (
          <span> </span>
        )}
      </div>
    </span>
  );
};
