// Función para formatear la fecha
export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = formatNumber(date.getDate());
  const month = formatNumber(date.getMonth() + 1);
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const getMonthName = (dateString: string) => {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const date = new Date(dateString);
  const monthIndex = date.getMonth();

  return months[monthIndex];
};

// format date to "YYYY-mm-dd HH:ii:ss"
export const formatDate_YMD_HIS = (date: Date): string => {
  const year = date.getFullYear();
  const month = formatNumber(date.getMonth() + 1);
  const day = formatNumber(date.getDate());
  const hours = formatNumber(date.getHours());
  const minutes = formatNumber(date.getMinutes());
  const seconds = formatNumber(date.getSeconds());
  
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const changeLastCharacter = (dateString: string): string => {
  const lastChar = dateString.charAt(dateString.length - 1);
  const newLastChar = lastChar === '0' ? '1' : '0';
  return dateString.slice(0, -1) + newLastChar;
}

const formatNumber = (number: number): string => {
  return number < 10 ? '0' + number : '' + number;
};
