import './PrivacyNotice.scss';

export const PrivacyNotice = () => {
  return (
    <div className="privacy-notice__container">
      <h2 className="privacy-notice__title">SDM Benchmarking Tool Privacy Notice</h2>
      <h3>Introduction</h3>
      <p>
        <em>Effective date of this privacy notice: December 2020</em> <br /> <br />
        This Privacy Notice (“Notice”) explains the type of personal data we may collect from you and how we use it when we are using the SDM (Service Development Manager) Benchmarking Tool Application (“Application”) to collect Information from you.{' '}
        <br />
        <br />
        The SDM Benchmarking Tool is made available by Takeda UK Ltd, a wholly owned subsidiary of Takeda Pharmaceutical Company Limited (hereinafter referred to collectively as “<strong>Takeda</strong>”) and is operated and managed by our service
        provider Engage in Healthcare (for more details – see How to Contact Us section below). For contact Information related to your local affiliate, please refer to Takeda website at{' '}
        <a href="https://www.takeda.com/countries" target="_blank" rel="noreferrer">
          www.takeda.com/countries
        </a>
        . <br />
        <br /> Before we collect Information from you using the Application, you should read this entire Notice (together with the Terms of Use). If the processing of personal data as described in this Notice is not acceptable to you, do not use
        and/or submit any personal Information.
      </p>
      <h3>What the Application can be used for</h3>
      <p>
        The Application assists ADHD Services to monitor the quality of care they provide to patients with a diagnosis of ADHD. The Application is held on iPads of all members of the Service Development Team at Takeda. Your local Service Development
        Manager can meet with you to complete a NICE Quality Standards Questionnaire. Once completed, the Application produces a report and spidergram that can be emailed to you. The NICE Quality Standards Questionnaire can be repeated after the
        service redesign and compared directly to the baseline report. Each completed report will be stored anonymously in a secure database within the Application.
      </p>
      <h3>What Information is collected</h3>
      <p>
      When the Application is used by your local Service Development Manager, we will collect Information about your ADHD service provision, which will include: Name of Project, Name of Project Lead, Email address of Project Lead and the results of the NICE Quality Standard Questionnaire.
        <br />
        <br />We will collect Information about you when you interact with us such as when you report a problem, contact or communicate with us.
      </p>
      <h3>How we use the Information we collect</h3>
      <p>We process the Information to provide you with the above-mentioned functions and to provide you with your programs report and spidergram.</p>
      <h3>Keeping your Information secure</h3>
      <p>
      Your Information is accessible to the Service Development Team at Takeda.<br />  Takeda personnel will ensure that their user IDs and passwords are kept
confidential. You are responsible for maintaining confidentiality of any reports sent to you.
      </p>
      <h3>Sharing your Information</h3>
      <p>
        We may share your Information with our authorized service providers that perform certain services on our behalf such as providing storage services and providing support for the Application. These service providers may have limited access to
        your Information, which is needed to perform their functions, but are not permitted to share or use such Information for any other purposes. <br />
        <br />
        Takeda desires to use the data for own purposes as described in this notice and offers you the choice to allow / not allow. If you do allow Takeda to use your Information, we will use it to help improve patients’ journey. <br />
        <br /> You are consenting to allow Takeda to use your personal data in a pseudonymized form. Pseudonymization means that identifying Information (such as your name etc.) are removed and replaced with a unique code, so your Information can
        only be related back to you if you have the code. The coded Information does not directly identify you. <br /> <br /> We may also have to share your Information for regulatory reporting purposes or legal obligations.
      </p>
      <h3>Where we store your Information</h3>
      <p>
        The Information collected from you is stored on an on-line Application server. The Information that we collect from you may be transferred to, and stored at, a destination outside of your home country. These destination countries may have
        different or less protective privacy laws than those in your home country. The Information may also be processed by any service providers appointed by us who operate outside of your home country and their staff, and/or our own staff based
        outside of your home country. By permitting us to collect your Information, you agree to this transfer, storing or processing outside your home country. Information transferred will be treated in accordance with this Notice.
      </p>
      <h3>How long we store your Information</h3>
      <p>Takeda will only retain Information for as long as necessary to serve the purpose(s) for which it was collected or to the extent necessary to comply with applicable legal requirement(s).</p>
      <h3>Access to and choices regarding your Information</h3>
      <p>
        If you would like to make a request for Takeda to access, correct or delete personal data that you have provided to Takeda, please contact us by accessing our webform{' '}
        <a href="https://www.takeda.com/individualrights" target="_blank" rel="noreferrer">
          www.takeda.com/individualrights
        </a>{' '}
        , and we will respond in a reasonable time. Where you have provided your consent for us to process your Information, you have the right to withdraw your consent. If you have a complaint about how your Information is being processed, you also
        have the right to contact the supervisory authority in your home country. If you wish to change your preferences, please contact your local Service Development Manager.
      </p>
      <h3>How to contact us</h3>
      <p>
      For questions about this notice please contact us at: <br /> Email Address: <a href="mailto:privacyoffice@takeda.com">privacyoffice@takeda.com</a> <br />
          {/* <br /> For more detailed information about how Takeda processes your personal data and
        your rights related to this processing please refer to Takeda’s Privacy Notice at{' '}
        <a href="https://www.takeda.com" target="_blank" rel="noreferrer">
          www.takeda.com
        </a> */}
      </p>
    </div>
  );
};
