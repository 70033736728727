import { useEffect, useRef } from 'react';
import { MyStepper } from '../../components/mystepper/MyStepper';
import { QuestionnaireContext, createQuestionnaireStore } from '../../../store/QuestionnaireStore';
import { QuestionnaireHeader } from '../../components/questionnaireheader/QuestionnaireHeader';
import { QuestionnaireBody } from '../../components/questionnairebody/QuestionnaireBody';
import { QuestionnaireFooter } from '../../components/questionnairefooter/QuestionnaireFooter';
import { useProjectDetailsStore } from '../../../store/ProjectDetailsStore';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectType } from '../../../models/view/Project';

export const Questionnaire = () => {
  const [project, incompleteBenchmark, discardIncompleteBenchmark] = useProjectDetailsStore((store) => [store.project, store.incompleteBenchmark, store.discardIncompleteBenchmark]);
  const projectId = useParams().projectId!!;
  const { type: projectType } = { ...project };
  const store = useRef(createQuestionnaireStore(projectType ? projectType : ProjectType.Adult, Number(projectId), incompleteBenchmark, discardIncompleteBenchmark)).current;
  const navigate = useNavigate();
  useEffect(() => {
    if (project === null) {
      navigate(`./projects/${projectId}`);
    }
  }, [project, navigate, projectId]);

  if (project === null) {
    return null;
  }

  return (
    <QuestionnaireContext.Provider value={store}>
      <MyStepper />
      <QuestionnaireHeader />
      <QuestionnaireBody />
      <QuestionnaireFooter />
    </QuestionnaireContext.Provider>
  );
};
