import { create } from 'zustand';

import { type ProjectsState } from '../models/store/ProjectsState';
import { ProjectController } from '../datasource/ProjectController';
import { handleUnauthorizedError } from '../utils/errorsHandler';

export const useProjectsStore = create<ProjectsState>((set) => {
  const projectProvider = new ProjectController();

  return {
    projects: [],
    loading: false,
    fetchProjects: async () => {
      set({ loading: true });
      try {
        const projects = await projectProvider.getAllProjects();
        set({ projects, loading: false });
      } catch (error: any) {
        await handleUnauthorizedError(error);
      }
    },
    addProject: (project) => {
      set((store) => {
        return { projects: [...store.projects, project] };
      });
    },
  };
});
