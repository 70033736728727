import { useEffect, useState } from 'react';
import { ItemHeaderDetails } from '../../../benchmarkTable/HeaderDetails/model/ItemHeaderDetails';
import { useProjectDetails } from '../../../../routes/projectdetails/hooks/useProjectDetails';
import { getMonthName } from '../../../../../utils/formatDate';
import { useQuestionnaireStore } from '../../../../routes/questionnaire/hooks/useQuestionnaireStore';

export const useHeaderDetails = () => {
  const { project } = useProjectDetails();
  const [itemsHeaderDetails, setItemsHeaderDetails] = useState<ItemHeaderDetails[]>([]);
  const { date, setDate } = useQuestionnaireStore();

  useEffect(() => {
    if (project) {
      const month = getMonthName(project.startDate);
      let type = '';

      if (project.type === 'Adult') {
        type = 'Adult Benchmark';
      } else {
        type = 'CYP Benchmark';
      }
      setItemsHeaderDetails([
        { title: month, value: type },
        { title: 'Takeda SDM', value: project?.takedaRepr },
        { title: 'Project Lead', value: project?.NHSProjLead },
      ]);
    }
  }, [project]);

  return { itemsHeaderDetails, date, setDate };
};
