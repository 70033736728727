import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';

import { useQuestionnaireStore } from '../../routes/questionnaire/hooks/useQuestionnaireStore';
import './MyStepper.scss';

export const MyStepper = () => {
  let { steps, indexCurrentStep, questionnaire, moveToStep } = useQuestionnaireStore();
  const categoryIndexOfLastCompletedCategory = questionnaire.getCategoryIndexOfLastCompletedCategory()
  const lastStepEnabled = categoryIndexOfLastCompletedCategory + 1

  steps = steps.map((step, index) => {
    return { ...step, disabled: index > lastStepEnabled };
  });

  const handleChange = (e: StepperChangeEvent) => {
    moveToStep(e.value);
  };

  return (
    <div className="stepper__container">
      <Stepper value={indexCurrentStep} items={steps} onChange={handleChange} />
    </div>
  );
};
