import { FC } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { AnswerOptionProps } from './model/AnswerOptionProps';
import './AnswerOption.scss';

export const AnswerOption: FC<AnswerOptionProps> = ({ optionValue, description, isSelected, handleClick }) => {
  const buttonClassName = isSelected ? 'answer-option__button answer-option__button--selected' : 'answer-option__button';

  return (
    <div className="answer-option__container">
      <Button className={buttonClassName} fillMode={'outline'} themeColor={'primary'} onClick={() => handleClick(optionValue)}>
        {optionValue ? optionValue : 'X'}
      </Button>
      <h6 className="answer-option__description">{description}</h6>
    </div>
  );
};
