import { useQuestionnaireStore } from '../../routes/questionnaire/hooks/useQuestionnaireStore';
import { AnswerOptionProps } from '../answeroption/model/AnswerOptionProps';
import { AnswerOptionGroup } from '../answeroptiongroup/AnswerOptionGroup';
import './QuestionnaireQuestion.scss';

export const QuestionnaireQuestion = () => {
  const { currentQuestion, setAnswer } = useQuestionnaireStore();
  const answerOptions: AnswerOptionProps[] = [
    { optionValue: 0, description: 'N/A', isSelected: currentQuestion.answer === 0, handleClick: setAnswer },
    { optionValue: 1, description: 'Never/Poor', isSelected: currentQuestion.answer === 1, handleClick: setAnswer },
    { optionValue: 2, isSelected: currentQuestion.answer === 2, handleClick: setAnswer },
    { optionValue: 3, isSelected: currentQuestion.answer === 3, handleClick: setAnswer },
    { optionValue: 4, isSelected: currentQuestion.answer === 4, handleClick: setAnswer },
    { optionValue: 5, isSelected: currentQuestion.answer === 5, handleClick: setAnswer },
    { optionValue: 6, description: 'Always/Excellent', isSelected: currentQuestion.answer === 6, handleClick: setAnswer },
  ];

  return (
    <div className="questionnaire-question__container">
      <h3 className="questionnaire-question__question-beggining">How effectively does your local service...</h3>
      <p className="questionnaire-question__question-body">{currentQuestion.text}</p>
      <AnswerOptionGroup answerOptions={answerOptions} />
    </div>
  );
};
