import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const addElementToPage = async (pdf: jsPDF, element: HTMLElement, remainingHeight: number, pageWidth: number, pageHeight: number) => {
  try {
    const elementCanvas = await html2canvas(element, { scale: 1 });
    const currentElementHeight = elementCanvas.height / (elementCanvas.width / pageWidth);

    pdf.addImage(elementCanvas, 'PNG', 2, pageHeight - remainingHeight - 2, pageWidth - 4, currentElementHeight);
    remainingHeight -= currentElementHeight;
    return remainingHeight;
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw new Error('Error generating PDF. Please try again.');
  }
};

const addPageWithPadding = (pdf: jsPDF, pageWidth: number, pageHeight: number) => {
  pdf.addPage();
  pdf.setFillColor('#F8F9FA');
  pdf.rect(0, 0, pageWidth, pageHeight, 'F');
};

const addElementOrPage = async (pdf: jsPDF, currentElement: HTMLElement, remainingHeight: number, pageWidth: number, pageHeight: number) => {
  try {
    const currentElementCanvas = await html2canvas(currentElement);
    const currentElementHeight = currentElementCanvas.height / (currentElementCanvas.width / pageWidth);

    if (currentElementHeight <= remainingHeight) {
      remainingHeight = await addElementToPage(pdf, currentElement, remainingHeight, pageWidth, pageHeight);
    } else {
      addPageWithPadding(pdf, pageWidth, pageHeight);
      remainingHeight = pageHeight - 4;
      remainingHeight = await addElementToPage(pdf, currentElement, remainingHeight, pageWidth, pageHeight);
    }
    return remainingHeight;
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw new Error('Error generating PDF. Please try again.');
  }
};

export const handleGenerateOneReportPdf = async () => {
  const chartElement = document.querySelector('.questionnaire-report__chart-section--container') as HTMLElement | null;
  const detailElement = document.querySelector('.item-detail__container') as HTMLElement | null;
  const categoryDetailsElementList = document.querySelectorAll('.category-details__container');
  // const footerElement = document.querySelector('.footer__content') as HTMLElement | null;
  const headerElement = document.querySelector('.header-element-export') as HTMLElement | null;

  if (!chartElement || !detailElement || !categoryDetailsElementList.length || !headerElement) {
    console.error('Element not found.');
    return;
  }

  const pdf = new jsPDF({compress: true});
  const imgWidth = 210;
  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();
  let remainingHeight = pageHeight - 4;

  try {
    pdf.setFillColor('#F8F9FA');
    pdf.rect(0, 0, imgWidth, pageHeight, 'F');

    remainingHeight = await addElementOrPage(pdf, headerElement, remainingHeight, pageWidth, pageHeight);
    remainingHeight = await addElementOrPage(pdf, detailElement, remainingHeight, pageWidth, pageHeight);
    remainingHeight = await addElementOrPage(pdf, chartElement, remainingHeight, pageWidth, pageHeight);

    for (let i = 0; i < categoryDetailsElementList.length; i++) {
      const categoryElement = categoryDetailsElementList[i];

      if (categoryElement instanceof HTMLElement) {
        const headerCategoryElement = categoryElement.querySelector('.pdf-category-header') as HTMLElement;
        const questionCategoryElements = categoryElement.querySelectorAll('.pdf-category-question');
        remainingHeight = await addElementOrPage(pdf, headerCategoryElement, remainingHeight, pageWidth, pageHeight);

        for (let index = 0; index < questionCategoryElements.length; index++) {
          const currentElement = questionCategoryElements[index] as HTMLElement;
          remainingHeight = await addElementOrPage(pdf, currentElement, remainingHeight, pageWidth, pageHeight);
        }
      }
    }

    // remainingHeight = await addElementOrPage(pdf, footerElement, remainingHeight, pageWidth, pageHeight);

    const pdfFile: any = pdf;
    
    const pdfBase64 = pdfFile.output('datauristring').split(',')[1];

    return pdfBase64;


  } catch (error) {
    console.error('Error generating PDF:', error);
    alert('Error generating PDF. Please try again.');
  }
};
