import { Loader } from '@progress/kendo-react-indicators';
import { useProjectDetails } from './hooks/useProjectDetails';
import HeaderDetails from '../../components/benchmarkTable/HeaderDetails';
import HeaderButtons from '../../components/benchmarkTable/headerButton/HeaderButtons';
import { BenchmarksTable } from '../../components/benchmarkTable/BenchmarkTable';
import useSelectedBenchmarks from './hooks/useSelectedBenchmarks';
import { useBenchmarkProgress } from './hooks/useBenchmarkDetails';
import IncompleteBenchmarkModal from '../../components/incompletebenchmarkmodal/IncompleteBenchmarkModal';
import { useShowDialog } from '../../hooks/useShowDialog';
import './ProjectDetails.scss';
import { useEffect, useRef } from 'react';

export const ProjectDetails = () => {
  const { project, loading, itemsHeaderDetails, completedBenchmarks, incompleteBenchmark } = useProjectDetails();
  const { benchmarkDetails } = useBenchmarkProgress(incompleteBenchmark, project);
  const { selectedIds, handleSelectChange, selectAllChecked, handleSelectAllChange } = useSelectedBenchmarks(completedBenchmarks);
  const { visible: isIncompleteBenchmarkDialogVisible, toggleDialog: toggleIncompleteBenchmarkDialog } = useShowDialog();
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current && incompleteBenchmark && !loading) {
      toggleIncompleteBenchmarkDialog();
      isFirstRender.current = false
    }
  }, [incompleteBenchmark, toggleIncompleteBenchmarkDialog, loading]);

  return (
    <div className="div__container">
      {loading ? (
        <Loader className="dashboard__loader" type="converging-spinner" />
      ) : (
        <div className=".p__headerButtons">
          <HeaderButtons selectedIds={selectedIds} incompleteBenchmarkDetails={benchmarkDetails} />
          {project && <HeaderDetails itemsHeaderDetails={itemsHeaderDetails} />}

          <div className="div__principal__text">
            {selectedIds.length > 12 ? <p className="p__text__error">Comparison Report available for up to 12 questionnaires </p> : <p className="p__text">Select one or multiple benchmarks to create reports </p>}
          </div>
          <div className="div__benchmarksTable">
            <BenchmarksTable benchmarks={completedBenchmarks} handleSelectChange={handleSelectChange} selectAllChecked={selectAllChecked} handleSelectAllChange={handleSelectAllChange} selectedIds={selectedIds} />
          </div>
          {isIncompleteBenchmarkDialogVisible && benchmarkDetails && <IncompleteBenchmarkModal benchmarkDetails={benchmarkDetails} onClose={toggleIncompleteBenchmarkDialog} />}
        </div>
      )}
    </div>
  );
};
