import { type Project } from '../models/view/Project';

interface ProjectProperty {
  id: string;
  shortTitle: string;
  longTitle: string;
}

export const projectProperties: Record<keyof Project, ProjectProperty> = {
  id: { id: 'id', shortTitle: 'ID', longTitle: 'ID' },
  name: { id: 'name', shortTitle: 'Project Name', longTitle: 'Project Name' },
  takedaRepr: { id: 'takedaRepr', shortTitle: 'Takeda SDM.', longTitle: 'Takeda SDM' },
  NHSProjPartner: { id: 'NHSProjPartner', shortTitle: 'Proj Partner', longTitle: 'Project Partner' },
  NHSProjLead: { id: 'NHSProjLead', shortTitle: 'Proj Lead', longTitle: 'Project Lead' },
  NHSLeadEmail: { id: 'NHSLeadEmail', shortTitle: 'Lead Email', longTitle: 'Lead Email' },
  type: { id: 'type', shortTitle: 'Type', longTitle: 'Type' },
  startDate: { id: 'startDate', shortTitle: 'Start Date', longTitle: 'Start Date' },
};
