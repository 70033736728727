import { ReactNode } from 'react';
import { QuestionnaireScreen } from '../../../utils/enums';
import { useQuestionnaireStore } from '../../routes/questionnaire/hooks/useQuestionnaireStore';
import { QuestionnaireOverview } from '../questionnaireoverview/QuestionnaireOverview';
import { QuestionnaireQuestion } from '../questionnairequestion/QuestionnaireQuestion';
import { QuestionnaireCategoryRecap } from '../questionnairecategoryrecap/QuestionnaireCategoryRecap';
import { QuestionnaireRecap } from '../questionnairerecap/QuestionnaireRecap';

export const QuestionnaireBody = () => {
  const { currentScreen } = useQuestionnaireStore();

  //{/* only this section could have vertical scroll mainly in QuestionnaireRecap */}
  //<div>QuestionnaireOverview || Question || CategoryRecap || QuestionnaireRecap</div>
  let CurrentScreen: ReactNode;

  switch (currentScreen) {
    case QuestionnaireScreen.OVERVIEW:
      CurrentScreen = <QuestionnaireOverview />;
      break;
    case QuestionnaireScreen.QUESTION_REVIEW:
    case QuestionnaireScreen.QUESTION:
      CurrentScreen = <QuestionnaireQuestion />;
      break;
    case QuestionnaireScreen.CATEGORY_RECAP:
      CurrentScreen = <QuestionnaireCategoryRecap />;
      break;
    case QuestionnaireScreen.QUESTIONNAIRE_RECAP:
      CurrentScreen = <QuestionnaireRecap />;
      break;
  }

  return CurrentScreen;
};
