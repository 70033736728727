import { create } from 'zustand';

import { ProjectDetailsState } from '../models/store/ProjectDetailsState';
import { BenchmarkController } from '../datasource/BenchmarkController';
import { handleUnauthorizedError } from '../utils/errorsHandler';

export const useProjectDetailsStore = create<ProjectDetailsState>((set, get) => {
  const benchmarkProvider = new BenchmarkController();

  return {
    project: null,
    benchmarks: [],
    loading: false,
    discardIncompleteBenchmark: false,
    setProject: (project) => set({ project }),
    refreshBenchmarks: async () => {
      set({ loading: true });
      try {
        const benchmarks = await benchmarkProvider.getBenchmarksByProjectId(get().project!!.id);
        set({ benchmarks, loading: false });
      } catch (error: any) {
        await handleUnauthorizedError(error);
        if (error.response && error.response.status === 400) {
          // No benchmarks found for the given project ID
          set({ benchmarks: [], loading: false });
        }
      }
    },
    setIncompleteBenchmark: (benchmark) => {
      set({ incompleteBenchmark: benchmark });
    },
    setDiscardIncompleteBenchmark: (discard) => {
      set({ discardIncompleteBenchmark: discard });
    },
  };
});
