import React from "react";
import { useBenchmarkDetailsArray } from "./hooks/useBenchmarkDetailsArray";
import { AnswerType } from "../../../models/view/Question";
import { useParams } from "react-router-dom";
import { CategoryDetailsProps } from "../categorydetails/model/CategoryDetailsProps";
import { Header } from "../header/Header";
import { useAuthVerify } from "../../mainlayout/hooks/useAuthVerify";
import { formatDate } from "../../../utils/formatDate";
import "./AnswersGrid.scss";

const AnswersGrid = ({ selectedIdsStore }: { selectedIdsStore: number[] }) => {
  const { username } = useAuthVerify();
  const projectId = useParams().projectId;
  const { categoryDetailsList } = useBenchmarkDetailsArray(selectedIdsStore);
  const shownCategories: Record<string, boolean> = {};
  const currentDate = Date();
  const formattedDate = formatDate(currentDate);

  const getAnswersForQuestionId = (questionId: number) => {
    let answers: AnswerType[] = [];
    categoryDetailsList.forEach((category) => {
      category.questionDetailsList.forEach((question) => {
        if (question.questionId === questionId) {
          if (Array.isArray(question.answer)) {
            answers = question.answer as AnswerType[];
          } else {
            console.error("Answer is not and array:", question.answer);
          }
        }
      });
    });
    return answers || [];
  };

  const getAveragesByCategory = (category: CategoryDetailsProps): string[] => {
    const averages: string[] = []; // e.g: ["2.6/6", "3.8/6", "2/6"]

    // row list
    // each inner array is a list of answers for a question
    const answersListByQuestions: AnswerType[][] = []; // e.g: [[2, 3, 4],[3, 6 ,4]]

    // column list
    // each inner array is a list of answers for a benchmark in the current category
    const answersByBenchmarkList: number[][] = []; // e.g: [[2, 3, 4],[3, 6 ,4]]

    // Step 1: Get all answers for each question
    category.questionDetailsList.forEach((questionDetails) => {
      const answers = getAnswersForQuestionId(questionDetails.questionId);
      answersListByQuestions.push(answers);
    });

    // Get the number of answers per question (assuming all questions have the same number of answers)
    const answersCountPerQuestion = answersListByQuestions[0].length;

    // Step 2 and 3: Group answers by category
    for (let i = 0; i < answersCountPerQuestion; i++) {
      const answersForCategory: number[] = [];
      answersListByQuestions.forEach((answersListByQuestion) => {
        if (answersListByQuestion[i] !== 0) {
          answersForCategory.push(answersListByQuestion[i]);
        }
      });
      answersByBenchmarkList.push(answersForCategory);
    }

    // Now answersByBenchmarkList will contain answers grouped by benchmark
    answersByBenchmarkList.forEach((answersByBenchmark) => {
      // eg for answersByBenchmark = [1, 6 , 5, 4]
      // sum is equal to 16
      const sum = answersByBenchmark.reduce((acc, answer) => acc + answer, 0);
      // average = 16 / answersByBenchmark.length => 16 / 4 = 4
      const average =
        answersByBenchmark.length > 0 ? sum / answersByBenchmark.length : 0;
      const roundedAverage = parseFloat(average.toFixed(1));

      // "/6" the number 6 is the max value of possible answer value
      // (answer can be a value between 0 and 6)
      averages.push(`${roundedAverage}/6`);
    });

    return averages;
  };

  return (
    <div className="answers-grid-component__container">
      <div className="header-element__topdf">
        <Header
          headerText={`${username}, Takeda SDM`}
          exportText={`${formattedDate} Export`}
        />
      </div>
      <div className="answers-grid-topdf">
        <div className="benchmarksId-grid__container">
          <div />
          <div className="benchmarkId-grid-container">
            {selectedIdsStore.map((benchmarkId) => (
              <div key={benchmarkId} className="benchmarkId-grid__container">
                <div className="benchmarkId-grid">
                  <div>ID#{benchmarkId}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="category-container__border">
          {categoryDetailsList.map((category, categoryIndex) => (
            <div key={categoryIndex} className="answer-grid-container">
              <div className="questions-container pdf-category-header" style={{ height: "52px" }}>
                <div className="category-name__container">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 500,
                    }}
                  >
                    <div>ID: {projectId}</div>
                    <div className="category-name-text">
                      {category.headerProps.categoryName}
                    </div>
                  </div>
                  <div>
                    <div className="average-label">AVERAGE</div>
                  </div>
                </div>

                {/* averages */}
                <div className="grid-benchmarkid average-background">
                  {getAveragesByCategory(category).map((average, index) => (
                    <div
                      key={index}
                      className="average-grid__container"
                      style={{
                        borderInline: "1px solid #DEE2E6",
                        width: "100%",
                      }}
                    >
                      <div className="average">
                        <div>{average}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {category.questionDetailsList.map((question, questionIndex) => (
                <div key={questionIndex} className="questions-container pdf-category-question">
                  <div className="question-grid__container">
                    <div className="questionId-question-text__container">
                      <div>
                        <div className="questionId">{question.questionId}</div>
                      </div>
                      <div
                        className="question-text__container"
                        style={{
                          height:
                            selectedIdsStore.length >= 9
                              ? "auto"
                              : selectedIdsStore.length == 9
                              ? "105px"
                              : "65px",
                        }}
                      >
                        <div className="question-text">{question.question}</div>
                      </div>
                    </div>
                  </div>
                  <div className="answers-container">
                    {getAnswersForQuestionId(question.questionId).map(
                      (answer, answerIndex) => (
                        <div key={answerIndex} className="answer-content">
                          <div className="answer">
                            <div>{answer}</div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AnswersGrid;

