import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { shareIcon } from "@progress/kendo-svg-icons";
import "./SendButton.scss";

const SendButton = ({ children, onClick }: any) => {


  return (
    <Button
      className="create-report__button"
      themeColor={"primary"}
      svgIcon={shareIcon}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default SendButton;
