import { type LoginRequest } from '../models/request/LoginReques';
import { type LoginResponse } from '../models/response/LoginResponse';

export const login = async (loginRequest: LoginRequest) => {
  const requestOptions = new Request(`${process.env.REACT_APP_BASE_URL}/user/login`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(loginRequest),
  });

  const response = await fetch(requestOptions);

  if (response.ok) {
    const data: LoginResponse = await response.json();
    return data;
  } else {
    throw new Error(`Error: ${response.status} - ${response.statusText}`);
  }
};
