import { useEffect, useState } from 'react';
import { Questionnaire, QuestionnaireAdult, QuestionnaireChild } from '../../../../utils/questionnaires';
import { Project, ProjectType } from '../../../../models/view/Project';
import { Benchmark } from '../../../../models/view/Benchmark';
import { BenchmarkDetail } from '../../../components/benchmarkTable/headerButton/model/HeaderButtonsProps';

export const useBenchmarkProgress = (benchmark?: Benchmark, project?: Project | null) => {
  const [benchmarkDetails, setBenchmarkDetails] = useState<BenchmarkDetail[]>();

  useEffect(() => {
    if (benchmark) {
      let questionnaire: Questionnaire;
      if (project?.type === ProjectType.Adult) {
        questionnaire = new QuestionnaireAdult();
      } else {
        questionnaire = new QuestionnaireChild();
      }

      benchmark.answers.forEach((answer) => {
        questionnaire.setAnswerbyQuestionId(answer.questionId, answer.answer);
      });

      const benchmarkProgress = `${questionnaire.getAnsweredQuestionsAmount()}/${questionnaire.getQuestionsAmount()}`;

      setBenchmarkDetails([
        { title: 'Benchmark', value: benchmark?.id },
        { title: 'Project Name', value: project?.name },
        { title: 'Project Partner', value: project?.NHSProjPartner },
        { title: 'Project Lead', value: project?.NHSProjLead },
        { title: 'Total Answered', value: benchmarkProgress },
        { title: 'Start Date', value: benchmark?.startDate?.substring(0, 10) },
      ]);
    }
  }, [benchmark, project, setBenchmarkDetails]);

  return { benchmarkDetails };
};
