import React, { createContext, useContext, ReactNode, useState } from 'react';
import { type AuthContextProps } from '../models/store/AuthContextProps';

// Create context with initial values
const AuthContext = createContext<AuthContextProps | undefined>(undefined);

// Provider Component to envolve the app
export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState<string | null>(null);
  const [userId, setUserId] = useState<number | null>(null);

  const loginInApp = (username: string, userId: number) => {
    setIsAuthenticated(true);
    setUsername(username);
    setUserId(userId);
  };

  const logoutInApp = () => {
    setIsAuthenticated(false);
    setUsername(null);
    setUserId(null);
  };

  return <AuthContext.Provider value={{ isAuthenticated, username, userId, loginInApp, logoutInApp }}>{children}</AuthContext.Provider>;
};

// Custom hook to get the context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within AuthProvider');
  }
  return context;
};
