import { type Project } from '../models/view/Project';

export const getLatestProjects = (projects: Project[], amount: number): Project[] => {
  const sortedProjects = projects.sort((a, b) => {
    const dateA = new Date(a.startDate).getTime();
    const dateB = new Date(b.startDate).getTime();
    return dateB - dateA;
  });

  const latestProjects = sortedProjects.slice(0, amount);

  return latestProjects;
};

export const capitalize = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const getCompleteLinkByPath = (path: string): string => {
  const baseUrl = window.location.origin;
  return `${baseUrl}${path}`;
};
