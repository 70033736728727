import { FC } from "react";

import { DropdownFilter } from "../dropdownfilter/DropdownFilter";
import { type FilterGroupProps } from "./model/FilterGroupProps";
import "./FilterGroup.scss";
import { ProjectType } from "../../../models/view/Project";

export const FilterGroup: FC<FilterGroupProps> = ({ filters, onChange }) => {

  const filtersWithCYP = filters.map((filter) => {
    if (filter.id == "type") {
      const items = [ProjectType.Adult, "CYP"];
      return { ...filter, items };
    }
    return filter;
  });

  return (
    <span className="container">
      {filtersWithCYP.map((filter) => (
        <DropdownFilter
          key={filter.id}
          filter={filter}
          onFilterChange={onChange}
        />
      ))}
    </span>
  );
};
