export const CATEGORIES = {
  serviceOrganisation: 'Service Organisation',
  recognitionIdentificationReferral: 'Recognition, Identification, Referral',
  diagnosis: 'Diagnosis',
  informationAndSupport: 'Information & Support',
  managingADHD: 'Managing ADHD',
  medication: 'Medication',
  maintenanceAndMonitoring: 'Maintenance and Monitoring',
  reviewOfMedicationAndDiscontinuation: 'Review of Medication and Discontinuation',
  transition: 'Transition',
};
