import React from 'react';
import { Loader } from '@progress/kendo-react-indicators';
import { HeaderActionBar } from '../../components/headeractionbar/HeaderActionBar';
import HeaderDetails from '../../components/benchmarkTable/HeaderDetails';
import ReportsTable from '../../components/reportstable/ReportsTable';
import { useBenchmarkReports } from './hooks/useBenchmarkReports';
import './ReportsEvaluation.scss';

const ReportsEvaluation = () => {
  const { loading, project, itemsHeaderDetails, benchmarksComparisonReports, actionBack } = useBenchmarkReports();

  return (
    <div className="reports-evaluation__div-container">
      {loading ? (
        <Loader className="dashboard__loader" type="converging-spinner" />
      ) : (
        <div>
          <HeaderActionBar actionBack={actionBack} sectionTitle={'Reports - Service Evaluation'} />
          {project && <HeaderDetails itemsHeaderDetails={itemsHeaderDetails} />}
          <div className="reports-table__container-padding">
            <ReportsTable benchmarksComparisonReports={benchmarksComparisonReports} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportsEvaluation;
