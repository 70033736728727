import { create } from "zustand";
import { SelectedIdsProps } from "../ui/components/benchmarkTable/headerButton/model/UseSelectedIdStore";

const useSelectedIdsStore = create<SelectedIdsProps>((set, get) => ({
  selectedIdsStore: [],
  
  addSelectedIds: (ids) => set({ selectedIdsStore: ids }),
  removeSelectedIds: () => set({ selectedIdsStore: [] }),
}));

export default useSelectedIdsStore;
