import { QuestionnaireScreen } from '../../../utils/enums';
import { useQuestionnaireStore } from '../../routes/questionnaire/hooks/useQuestionnaireStore';
import { HeaderActionBar } from '../headeractionbar/HeaderActionBar';
import { InfoTag } from '../infotag/InfoTag';
import { InfoTagProps } from '../infotag/model/InfoTagProps';
import { QOHeaderActionBar } from '../questionnaireoverview/headeractionbar/QOHeaderActionBar';

export const QuestionnaireHeader = () => {
  const { currentScreen, handleBackCategory, currentCategoryOverview, questionnaireOverview } = useQuestionnaireStore();

  let children1: React.ReactNode = null;
  let children2: React.ReactNode = null;
  let sectionTitle = '';

  const infoTagPropsChildren2: InfoTagProps = { label: 'Total Answered', value: `${questionnaireOverview.answeredQuestions}/${questionnaireOverview.questionsAmount}` };
  switch (currentScreen) {
    case QuestionnaireScreen.OVERVIEW:
      break;
    case QuestionnaireScreen.QUESTION:
    case QuestionnaireScreen.QUESTION_REVIEW:
      const infoTagPropsChildren1: InfoTagProps = { label: 'Section Question', value: `${currentCategoryOverview.currentQuestionOrder}/${currentCategoryOverview.questionsAmount}` };
      children1 = <InfoTag {...infoTagPropsChildren1} />;
      children2 = <InfoTag {...infoTagPropsChildren2} />;
      sectionTitle = currentCategoryOverview.category;
      break;
    case QuestionnaireScreen.CATEGORY_RECAP:
      children2 = <InfoTag {...infoTagPropsChildren2} />;
      sectionTitle = currentCategoryOverview.category;
      break;
    case QuestionnaireScreen.QUESTIONNAIRE_RECAP:
  }
  return <>{currentScreen === QuestionnaireScreen.OVERVIEW ? <QOHeaderActionBar /> : <HeaderActionBar actionBack={handleBackCategory} sectionTitle={sectionTitle} children1={children1} children2={children2} textBackBtn='Previous Section' />}</>;
};
