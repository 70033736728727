import ItemDetail from "../benchmarkTable/HeaderDetails/itemDetail";
import ModalSendReportSuccessOrError from "../modalsuccessorerror/ModalSendReportSuccessOrError";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { useProjectDetails } from "../../routes/projectdetails/hooks/useProjectDetails";
import { Label } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import { DialogReportProp } from "./model/DialogReportContainerProps";
import { handleGenerateComparisonReportPdf } from "../../../utils/generateComparisonReportPDF";
import { handleGenerateOneReportPdf } from "../../../utils/generateOneReportPDF";
import { useEffect, useMemo, useState } from "react";
import { sendReportInfoType } from "../../../models/request/CreateReportRequest";
import useSendReportByEmail from "./hook/useSendReportByEmail";
import useSelectedIdsStore from "../../../store/useSelectedIds";
import { useParams } from "react-router-dom";
import { Loader } from "@progress/kendo-react-indicators";
import ModalSuccess from "../modalsuccessorerror/ModalSuccess";
import { ItemHeaderDetails } from "../benchmarkTable/HeaderDetails/model/ItemHeaderDetails";
import { Error as ErrorComponet } from '@progress/kendo-react-labels';
import "./childrendialogreport/ChildrenDialogReport.scss";
import "./DialogReportContainer.scss";

export const DialogReportContainer = ({
  onClose,
  isFromReportComparison,
}: DialogReportProp) => {
  const submitText = "Send";
  const title = isFromReportComparison
    ? "Send Comparison Report"
    : "Send Report";
  const date = new Date();
  const formattedCurrentDate = date.toLocaleDateString("en-US", {
    month: "long",
    year: "numeric",
  });
  const [isCancelled, setIsCancelled] = useState(false);
  const { handleSubmit, sendingError } = useSendReportByEmail(
    onClose,
    isCancelled
  );
  const { itemsHeaderDetails } = useProjectDetails();
  const { selectedIdsStore } = useSelectedIdsStore();
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState<boolean>(false);
  const benchmarkId = useParams().benchmarkId;
  const selectedBenchmarksIds =
    selectedIdsStore.length == 0 ? [Number(benchmarkId)] : selectedIdsStore;

  // Set Takeda SDM Email as default Email
  const takedaRepEmail = itemsHeaderDetails.find(
    (item) => item.title === "Takeda SDM"
  )?.value;

  const [emails, setEmails] = useState<string[] | undefined>([]);

  useEffect(() => {
    if (takedaRepEmail) setEmails([takedaRepEmail]);
  }, [takedaRepEmail]);

  const sendReportByEmail = async () => {
    if (!emails?.length) {
      setSubmitAttempted(true);
      setError("Please enter at least one email to send the report.");
      return;
    }
    setError("");
    setLoading(true);

    try {
      let zipBase64;

      if (isFromReportComparison) {
        zipBase64 = await handleGenerateComparisonReportPdf();
      } else {
        zipBase64 = await handleGenerateOneReportPdf();
      }

      const sendReportInfo = {
        pdfFile: zipBase64,
        emails: emails,
        benchmarks_id: selectedBenchmarksIds,
      };

      const result = await handleSubmit(sendReportInfo as sendReportInfoType);

      if (result === "success") {
        setOpenModalSuccess(true);
      } else {
        setModalError(true);
      }
    } catch (error) {
      console.error("Error sending email:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleDialog = () => {
    setIsCancelled(true);
    onClose && onClose();
  };

  useEffect(() => {
    if (openModalSuccess) {
      setTimeout(() => {
        setOpenModalSuccess(false);
      }, 5000);
    }
  }, [openModalSuccess]);

  const CustomTitleBar = () => {
    return <div className="dialog-title">{title}</div>;
  };

  const isEmailValid = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: any) => {
    const { value } = e.target;
    const emailList = value.split(" ").filter((email: any) => email.trim() !== "");
  
    const lastCharacterIsSpace = value.endsWith(" ");
  
    if (lastCharacterIsSpace) {
      const invalidEmails = emailList.filter((email: any) => !isEmailValid(email));
  
      if (invalidEmails.length > 0) {
        setError("*Please enter a valid email.");
      } else {
        setError("");
      }
    }
  
    setEmails(emailList);
  };
  
  

  return (
    <Dialog title={<CustomTitleBar />} closeIcon={true} onClose={toggleDialog}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          sendReportByEmail();
        }}
      >
        {loading ? (
          <div
            className="loader-container"
            style={{
              height: "350px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <p
                style={{
                  marginBottom: "10px",
                  fontWeight: 500,
                  paddingBottom: "20px",
                  fontSize: "20px",
                }}
              >
                Sending Report
              </p>
              <Loader
                className="modal__send-report__loader"
                type="converging-spinner"
              />
            </div>
          </div>
        ) : (
          <DialogActionsBar>
            <div>
              <div className="project-details__container">
                <div className="children-dialog__container">
                  {itemsHeaderDetails.map((itemDetail, index) => (
                    <div key={index}>
                      <ItemDetail
                        itemName={itemDetail.title}
                        item={itemDetail.value}
                      />
                    </div>
                  ))}
                  <ItemDetail
                    itemName={"Date of Preparation"}
                    item={formattedCurrentDate}
                  />
                </div>

                <div className="children-dialog__input-container">
                  <Label className="children-dialog__label">

                    E-mail recipient
                    {takedaRepEmail && (
                      <Input
                        defaultValue={`${takedaRepEmail} `}
                        className="children-dialog__input"
                        onChange={handleEmailChange}
                      />
                    )}
                  </Label>
                  {error ? <ErrorComponet className="error-message form__dot-error" style={{marginTop:"20px"}}>{error}</ErrorComponet> : <span></span> }

                  <div className="dialog-report-error">
                    {submitAttempted ? (
                      <span>{error}</span>
                    ) : (
                      <span style={{ color: "#212529" }}>
                        Add additional recipients separated by blank space
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </DialogActionsBar>
        )}
        <div className="dialog__footer-container">
          <Button
            className="dialog__button dialog__button--cancel button__cancel"
            themeColor={"primary"}
            onClick={toggleDialog}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            disabled={loading ? true : false}
            className="dialog__button button__text"
            themeColor={"primary"}
          >
            {submitText}
          </Button>
        </div>
      </form>

      {openModalSuccess && (
        <ModalSuccess setOpenModalSuccess={setOpenModalSuccess} />
      )}

      {modalError && (
        <ModalSendReportSuccessOrError
          submitText={"Try Again"}
          errorMessage={sendingError}
          sendByEmailFunction={sendReportByEmail}
          setModalError={setModalError}
        />
      )}
    </Dialog>
  );
};
