import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { CookieKeys } from '../../../../utils/enums';
import { useAuth } from '../../../../store/AuthProvider';
import { type LoginResponse } from '../../../../models/response/LoginResponse';
import { login } from '../../../../datasource/auth';

export const useLoginForm = () => {
  const [loginFailed, setLoginFailed] = useState(false);
  const { loginInApp } = useAuth();

  const userNameValidator = (value: string) => (value ? '' : 'Username is required.');
  const passwordValidator = (value: string) => (value ? '' : 'Password is required.');

  const navigate = useNavigate();

  const handleClick = async (formValues: any) => {
    const { userName, password } = formValues;

    try {
      const loginRequest = { username: userName, password: password };
      const data: LoginResponse = await login(loginRequest);

      const fullName = `${data.first_name} ${data.last_name}`;
      loginInApp(fullName, data.id);

      document.cookie = `${CookieKeys.ACCESS_TOKEN}=${data.bearerToken}; path=/;`;
      document.cookie = `${CookieKeys.USER_NAME}=${encodeURIComponent(fullName)}; path=/;`;
      document.cookie = `${CookieKeys.USER_ID}=${data.id}; path=/;`;

      navigate('/');
    } catch (error: any) {
      console.error('Login failed: ', error.message);
      setLoginFailed(true);
    }
  };

  return { handleClick, loginFailed, userNameValidator, passwordValidator };
};
