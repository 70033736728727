import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ProjectType, type Project } from '../../../../models/view/Project';
import { useProjectsStore } from '../../../../store/ProjectsStore';
import { useProjectDetailsStore } from '../../../../store/ProjectDetailsStore';
import { formatDate } from '../../../../utils/formatDate';
import { ItemHeaderDetails } from '../../../components/benchmarkTable/HeaderDetails/model/ItemHeaderDetails';
import { Benchmark } from '../../../../models/view/Benchmark';
import { Questionnaire, QuestionnaireAdult, QuestionnaireChild } from '../../../../utils/questionnaires';
import { getCookieValue } from '../../../../utils/cookies';
import { CookieKeys } from '../../../../utils/enums';

export const useProjectDetails = () => {
  const projectId = useParams().projectId;
  const [projects, fetchProjects, projectsLoading] = useProjectsStore((store) => [store.projects, store.fetchProjects, store.loading]);
  const [project, benchmarks, incompleteBenchmark, loading, setProject, refreshBenchmarks, setIncompleteBenchmark] = useProjectDetailsStore((store) => [
    store.project,
    store.benchmarks,
    store.incompleteBenchmark,
    store.loading,
    store.setProject,
    store.refreshBenchmarks,
    store.setIncompleteBenchmark,
  ]);
  const [itemsHeaderDetails, setItemsHeaderDetails] = useState<ItemHeaderDetails[]>([]);
  const [completedBenchmarks, setCompletedBenchmarks] = useState<Benchmark[]>([]);


  useEffect(() => {
    if (projects.length > 0 && !projectsLoading) {
      const selectedProject = projects.find((project) => project.id === parseInt(projectId!!)) as Project | undefined;
      if (selectedProject) {
        setProject(selectedProject);
      } else {
        throw new Error('Project not found');
      }
    } else {
      if (!projectsLoading && !loading) {
        fetchProjects();
      }
    }
  }, [setProject, projects, fetchProjects, projectId, projectsLoading, loading]);

  useEffect(() => {
    if (benchmarks.length > 0 && project && project.id === Number(projectId) && benchmarks[0].projectId === Number(projectId)) {
      const completed = [];
      const incomplete = [];

      for (const benchmark of benchmarks) {
        let questionnaire: Questionnaire;
        if (project.type === ProjectType.Adult) {
          questionnaire = new QuestionnaireAdult();
        } else {
          questionnaire = new QuestionnaireChild();
        }

        benchmark?.answers.forEach((answer) => {
          questionnaire.setAnswerbyQuestionId(answer.questionId, answer.answer);
        });

        if (questionnaire.isCompleted()) {
          completed.push(benchmark);
        } else {
          incomplete.push(benchmark);
        }
      }

      setCompletedBenchmarks(completed);

      const userId = Number(getCookieValue(CookieKeys.USER_ID)!!);
      const incompleteBenchmarCurreOfCurrentUser = incomplete.find((benchmark) => benchmark.ownerId === userId);
      setIncompleteBenchmark(incompleteBenchmarCurreOfCurrentUser);
    } else {
      setCompletedBenchmarks([]);
      setIncompleteBenchmark();
    }
  }, [benchmarks, setCompletedBenchmarks, project, setIncompleteBenchmark, projectId]);

  useEffect(() => {
    refreshBenchmarks();
  }, [project, refreshBenchmarks]);

  useEffect(() => {
    if (project) {
      let type = '';
      const formattedDate = project?.startDate ? formatDate(project?.startDate) : '';

      if (project.type === 'Adult') {
        type = 'Adult Project';
      } else {
        type = 'CYP Project';
      }
      setItemsHeaderDetails([
        { title: type, value: project?.name },
        { title: 'Takeda SDM', value: project?.takedaRepr },
        { title: 'Date', value: formattedDate },
        { title: 'Project Lead', value: project?.NHSProjLead },
      ]);
    }
  }, [project]);

  return {
    project,
    benchmarks,
    loading,
    itemsHeaderDetails,
    completedBenchmarks,
    incompleteBenchmark,
  };
};
