import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import "../../dialogcontainer/DialogContainer.scss";
import "../IncompleteBenchmarkModal.scss";
import "./ConfirmDiscardBenchmark.scss";

const CustomTitleBar = () => {
  return <div className="incomplete-benchmark__title">Please Confirm</div>;
};

const ConfirmDiscardModal = ({ onClose, discardAndStartNew }: any) => {
  return (
    <div>
      <Dialog title={<CustomTitleBar />} onClose={onClose} width={500} height={300}>
        <div className="confirm-modal__content">
          ¿Are you sure you want to discard the Benchmark?
        </div>
        <DialogActionsBar>
          <div className="dialog__footer-container">
            <Button
              className="dialog__button dialog__button--cancel"
              themeColor={"primary"}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button themeColor={"primary"} onClick={discardAndStartNew}>
              Discard and <br/> Start New Benchmark
            </Button>
          </div>
        </DialogActionsBar>
      </Dialog>
    </div>
  );
};

export default ConfirmDiscardModal;
