import React, { FC } from "react";
import { BenchmarkColorsGridProps } from "./BenchmarksColorsGridProps";
import "./BenchmarksColorsGrid.scss";

const BenchmarksColorsGrid: FC<BenchmarkColorsGridProps> = ({ dateColors }) => {
  return (
    
    <div className='component__container'>
      {dateColors.map((item, index) => 
      <div key={index}  className='map__container'>
        
      <div className="date-column">{item.benchmarkDate}</div>
      <div className="rectangle" style={{backgroundColor: item.color}}></div>
      </div>
      )}
    </div>
  );
};

export default BenchmarksColorsGrid;
