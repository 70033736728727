import { Error as ErrorComponet } from '@progress/kendo-react-labels';

const FormErrorDot = () => {
  return (
    <div className="form__dot-error__container">
      <ErrorComponet className="error-message form__dot-error">*</ErrorComponet>
    </div>
  );
};

export default FormErrorDot;
