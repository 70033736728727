import { useEffect } from 'react';
import { useProjectsStore } from '../../../../store/ProjectsStore';

export const useProjects = () => {
  const [projects, loading, fetchProjects] = useProjectsStore((store) => [store.projects, store.loading, store.fetchProjects]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  return {
    projects,
    loading,
  };
};
