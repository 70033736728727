import React, { useRef, useState } from "react";
import { QuestionsReport } from "../../components/questionsreport/QuestionsReport";
import { HeaderActionBar } from "../../components/headeractionbar/HeaderActionBar";
import { useNavigate, useParams } from "react-router-dom";
import HeaderDetails from "../../components/benchmarkTable/HeaderDetails";
import { useProjectDetails } from "../projectdetails/hooks/useProjectDetails";
import SendButton from "../../components/sendbutton/SendButton";
import { useProjects } from "../dashboard/hooks/useProjects";
import { Loader } from "@progress/kendo-react-indicators";
import { useBenchmarkDetails } from "../../components/questionsreport/hooks/useBenchmarkDetails";
import HeaderCategory from "../../components/headercategory/HeaderCategory";
import { RadarChart } from "../../components/radarchart/RadarChart";
import { DialogReportContainer } from "../../components/dialogreportcontainer/DialogReportContainer";
import Footer from "../../components/footer/Footer";
import "./QuestionnaireReport.scss";
import { handleGenerateOneReportPdf } from "../../../utils/generateOneReportPDF";
import { sendReportInfoType } from "../../../models/request/CreateReportRequest";
import html2canvas from "html2canvas";
import { Header } from "../../components/header/Header";
import { formatDate } from "../../../utils/formatDate";

const QuestionnaireReport = () => {
  const {
    categoryDetailsList,
    benchmarkId,
    categoryNames,
    categoryAverageGroup,
    questionnaireAverage,
  } = useBenchmarkDetails();

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const projectId = useParams().projectId;
  const { itemsHeaderDetails } = useProjectDetails();
  const { loading } = useProjects();
  const currentDate = Date();
  const formattedDate = formatDate(currentDate);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const actionBack = () => {
    navigate(`/projects/${projectId}`);
  };

  return (
    <div className="questionnaire-report__container">
      {loading ? (
        <Loader className="dashboard__loader" type="converging-spinner" />
      ) : (
        <>
          <HeaderActionBar
            actionBack={actionBack}
            sectionTitle="Report"
            children1={
              <SendButton
                onClick={openModal} // Open the modal to add the email to which we want to send
                // onClick={sendReportByEmail}
                // onClick={handleGenerateOneReportPdf}
              >
                Send Report
              </SendButton>
            }
            children2={
              showModal && (
                <DialogReportContainer
                  onClose={closeModal}
                  isFromReportComparison={false}
                />
              )
            }
          />
          <HeaderDetails
            itemsHeaderDetails={itemsHeaderDetails}
            isReportSection={true}
          />
          <div className="questionnaire-report__chart-section-container--external">
            <div className="questionnaire-report__chart-section--container">
              <HeaderCategory
                average={`${questionnaireAverage}/6`}
                benchmarkId={Number(benchmarkId)}
                categoryName="Spider Chart"
              />
              <div className="questionnaire-report__chart--container">
                <RadarChart
                  nameCategories={categoryNames}
                  categoryAverageGroup={categoryAverageGroup}
                />
              </div>
              <p>
                The spider diagram shows the average point on the rating scale
                fot each category. The outermost circle represents
                ´Always/Excellent´. The innermost circle represents ´N/A´.
              </p>
            </div>
          </div>
          <QuestionsReport categoryDetailsList={categoryDetailsList} />

          {/* <Footer /> */}
          {showModal && (
            <div className="header-element-export">
              <Header exportText={`${formattedDate} Export`} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default QuestionnaireReport;
