import { FC, useContext } from 'react';
import { BenchmarkIdsProps, QuestionnaireChartProps } from './model/QuestionnaireChartProps';
import BenchmarksColorsGrid from './benchmarkcolorsgrid/BenchmarksColorsGrid';
import { RadarChart } from '../radarchart/RadarChart';
import './QuestionnaireChart.scss';
import useBenchmarksDetails from './hooks/useBenchmarksDetails';
import { useCategoryDetails } from "../questionnairerecap/hooks/useCategoryDetails";
import { QuestionsReport } from "../questionsreport/QuestionsReport";

const QuestionnaireChart: FC<BenchmarkIdsProps> = ({benchmarkIds}) => {
  const { dateColors, nameCategories, categoryAverageGroup } = useBenchmarksDetails(benchmarkIds)

    return (

      <div className="chart-div__container">
      <RadarChart nameCategories={nameCategories} categoryAverageGroup={categoryAverageGroup} />
      <div className="chart__div-content">
        <p className="chart__text">The spider diagram shows the average point on the rating scale fot each category. The outermost circle represents ´Always/Excellent´. The innermost circle represents ´N/A´.</p>
        <BenchmarksColorsGrid dateColors={dateColors} />
      </div>
    </div>
  );
};

export default QuestionnaireChart;
