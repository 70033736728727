export enum ProjectType {
  Adult = 'Adult',
  Child = 'Child',
}

export interface Project {
  id: number;
  name: string;
  takedaRepr: string;
  NHSProjPartner: string;
  NHSProjLead: string;
  NHSLeadEmail: string; 
  type: ProjectType;
  startDate: string;
}
