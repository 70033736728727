import React, { FC, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate } from 'react-router-dom';
import { useCreateReport } from './hook/useCreateReport';
import useSelectedIdsStore from '../../../../store/useSelectedIds';
import IncompleteBenchmarkModal from '../../incompletebenchmarkmodal/IncompleteBenchmarkModal';
import { HeaderButtonsProps } from './model/HeaderButtonsProps';
import { useProjectDetailsStore } from '../../../../store/ProjectDetailsStore';
import './HeaderButton.scss';

const HeaderButtons: FC<HeaderButtonsProps> = ({ selectedIds, incompleteBenchmarkDetails: benchmarkDetails }) => {
  const navigate = useNavigate();
  const { addSelectedIds } = useSelectedIdsStore();
  const [project] = useProjectDetailsStore((store) => [
    store.project,
  ])
  const { handleCreateReportFunction } = useCreateReport(selectedIds);
  const isSelectedValid = selectedIds.length >= 2 && selectedIds.length <= 12;
  const isButtonDisabled = selectedIds.length > 12 || !isSelectedValid;
  const [openModal, setOpenModal] = useState(false);
  const [showDiscardButton, setShowDiscardButton] = useState(false);
  const isIPadMini = window.innerWidth <= 800;

  const handlerCreateReport = () => {
    if (!isButtonDisabled && isSelectedValid) {
      addSelectedIds(selectedIds);
      handleCreateReportFunction();
      navigate(`./create-report/${selectedIds[0]}`);
    }
  };

  const handleCompleteOrIncompleteBenchmarks = () => {
    if (benchmarkDetails) {
      setOpenModal(!openModal);
      setShowDiscardButton(true);
    } else {
      navigate('./questionnaire');
    }
  };

  const handleCloseActualModal = () => {
    setOpenModal(!openModal);
    setShowDiscardButton(false);
  };

  return (
    <div className="header-button__container">
      <span className="final__test__text">{project?.name}</span>
      <div className="div__container__buttons">
        <Button fillMode={'outline'} themeColor={'primary'} size={'large'} disabled={isButtonDisabled} className={'report__button'} onClick={handlerCreateReport}>
        <span className="button-text-responsive">Create Report</span> 
        </Button>
        <Button fillMode={'outline'} themeColor={'primary'} size={'large'} className="report__button" onClick={() => navigate('./reports')}>
        <span className="button-text-responsive"> View Reports</span>
        </Button>
        <Button themeColor={'primary'} size={'large'} className="create__benchmark__button report__button" onClick={handleCompleteOrIncompleteBenchmarks}>
        <span className="button-text-responsive">  Create New Benchmark</span>
        </Button>
        {openModal && benchmarkDetails && <IncompleteBenchmarkModal benchmarkDetails={benchmarkDetails} onClose={handleCloseActualModal} showDiscardButton={showDiscardButton} />}
      </div>
    </div>
  );
};

export default HeaderButtons;
