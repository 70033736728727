import { Card, CardBody } from '@progress/kendo-react-layout';

import { TakedaLogo } from '../../components/logo/TakedaLogo';
import { LoginForm } from '../../components/loginform/LoginForm';
import './Login.scss';

export const Login = () => {
  return (
    <div className="login">
      <Card className="login__card">
        <CardBody className="login__card-body">
          <div className="login-logo__container">
            <TakedaLogo />
          </div>
          <h1 className="login__title">SDM Benchmarking Tool</h1>
          <section className="login__form-container">
            <LoginForm />
          </section>
        </CardBody>
      </Card>
    </div>
  );
};
