import { useEffect, useState } from "react";
import { CategoryDetailsProps } from "../../categorydetails/model/CategoryDetailsProps";
import { CategoryAverage } from "../../questionnairechart/model/QuestionnaireChartProps";
import { useBenchmarkIdsArray } from "./useBenchmarksArray";

export const useBenchmarkDetailsArray = (benchmarkIds: number[]) => {
    const [categoryDetailsList, setCategoryDetailList] = useState<CategoryDetailsProps[]>([]);
    const [categoryNames, setCategoryNames] = useState<string[]>([]);
    const [categoryAverageGroup, setCategoryAverageGroup] = useState<CategoryAverage[][]>([]);
    const [questionnaireAverage, setQuestionnaireAverage] = useState<number>(0);
    const { questionnaire, benchmarkId } = useBenchmarkIdsArray(benchmarkIds);
  
    useEffect(() => {
        if (questionnaire) {
          const categoryDetails = questionnaire.getCompareCategoryDetailsList(String(benchmarkId));
          const categories = questionnaire.getCategories();
          const categoryAverageGroup = categories.map(category => ({
            categoryName: category,
            value: questionnaire.getAverageByCategory(category)
          }));
  
          setCategoryDetailList(categoryDetails);
          setCategoryNames(categories);
          setCategoryAverageGroup([categoryAverageGroup]);
          setQuestionnaireAverage(questionnaire.getQuestionnaireAverage());
        }
    }, [questionnaire, benchmarkIds]);
  
  
    return { categoryDetailsList, benchmarkIds, categoryNames, categoryAverageGroup, questionnaireAverage };
  };