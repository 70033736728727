import React, { FC } from "react";
import { HeaderCategoryProps } from "./model/HeaderCategoryProps";
import "./HeaderCategory.scss";

const HeaderCategory: FC<HeaderCategoryProps> = ({benchmarkId: id, categoryName, average }) => {
  return (
    <div className="header-category__div-container pdf-category-header">
      <div className="header-category__div-content">

        <div className="header-category__div-id">ID: {id}</div>

          <div className="header-category__div-name">{categoryName}</div>

        <div className="header-category__div-right header-category__div-name">{average}</div>
      </div>
    </div>
  );
};

export default HeaderCategory;
