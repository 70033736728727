import { ReportController } from "../../../../../datasource/ReportController";
import { useParams } from "react-router-dom";

export const useCreateReport = (selectedIds: any) => {
  const projectId = useParams().projectId;

  const handleCreateReportFunction = () => {
    if (projectId && selectedIds.length == 2) {
      if (selectedIds.length == 2) {
        const reportController = new ReportController();
        const reportInfo = {
          projectId: Number(projectId),
          benchmarkId1: selectedIds[0],
          benchmarkId2: selectedIds[1],
        };

        const reponse = reportController.generateReport(reportInfo);
      }
    }
  };
  return { handleCreateReportFunction };
};
