import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { QOHeaderDetails } from './headerdetails/QOHeaderDetails';
import { useQuestionnaireStore } from '../../routes/questionnaire/hooks/useQuestionnaireStore';
import { CategoryOverviewTableProps } from './model/CategoryOverviewTableProps';
import './QuestionnaireOverview.scss';

export const QuestionnaireOverview = () => {
  const { categoriesOverview } = useQuestionnaireStore();
  const categories = categoriesOverview.map((categoryOverview) => ({ category: categoryOverview.category, categoryOverview: `${categoryOverview.answeredQuestions}/${categoryOverview.questionsAmount} Questions` } as CategoryOverviewTableProps));

  return (
    <>
      <div className="questionnaire-overview__header-container">
        <QOHeaderDetails />
      </div>
      <div className="questionnaire-overview__table-container">
        <Grid scrollable="none" data={categories}>
          <GridColumn field="category" headerClassName="questionnaire-overview-table__header" />
          <GridColumn field="categoryOverview" headerClassName="questionnaire-overview-table__header" />
        </Grid>
      </div>
    </>
  );
};
