import { useQuestionnaireStore } from '../../../routes/questionnaire/hooks/useQuestionnaireStore';
import { CategoryDetailsProps } from '../../categorydetails/model/CategoryDetailsProps';

export const useCategoryDetails = () => {
  const { categoryDetailsList, handleEditQuestion } = useQuestionnaireStore();
  const categoryDetailsListWithEditFunc: CategoryDetailsProps[] = categoryDetailsList.map((categoryDetails) => {
    const questionDetailsList = categoryDetails.questionDetailsList.map((questionDetails) => {
      return { ...questionDetails, onEdit: () => handleEditQuestion(questionDetails.questionId) };
    });
    return { ...categoryDetails, questionDetailsList: questionDetailsList };
  });

  return { categoryDetailsList: categoryDetailsListWithEditFunc };
};
