import React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import "../dialogreportcontainer/DialogReportContainer.scss";

const ModalSendReportSuccessOrError = ({
  submitText,
  errorMessage,
  sendByEmailFunction,
  setModalError,
}: any) => {
  const CustomTitleBar = () => {
    return <div className="dialog-title">Error</div>;
  };


  const handleCloseModal = () => {
    setModalError(false);
  };

  const handleTryAgain = () => {
    setModalError(false);
    sendByEmailFunction();
  };

  const handleTryAgainClick = (e: any) => {
    e.preventDefault();
    handleTryAgain();
  };

  return (

        <Dialog title={<CustomTitleBar />} closeIcon={false} width={500}>
          <div
            style={{
              fontWeight: 500,
              height: "100px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "50px",
              fontSize: "20px",
            }}
          >
            <div>{errorMessage}</div>
          </div>
          <DialogActionsBar>
            <div className="dialog__footer-container">
              <Button
                className="dialog__button dialog__button--cancel"
                themeColor={"primary"}
                onClick={handleCloseModal}
              >
                Close
              </Button>
              <form onSubmit={handleTryAgainClick}>
                <Button
                  type="submit"
                  className="dialog__button"
                  themeColor={"primary"}
                >
                  {submitText}
                </Button>
              </form>
            </div>
          </DialogActionsBar>
        </Dialog>
   
  );
};

export default ModalSendReportSuccessOrError;
