import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { Button } from '@progress/kendo-react-buttons';

import { projectProperties } from '../../../utils/projects';
import { type MySwitchProps } from './model/MySwitchProps';
import { type CustomSwitchProps } from './model/CustomSwitchProps';
import './CustomSwitch.scss';

const MySwitch: FC<MySwitchProps> = ({ field, onClick, label }) => {
  return (
    <Button
      themeColor={'primary'}
      fillMode={`${field.value === label.toLowerCase() ? 'solid' : 'outline'}`}
      type="button"
      onClick={onClick}
      className={`custom-switch__button ${label === 'CYP' ? 'custom-switch__button--left' : 'custom-switch__button--right'}`}
    >
      {label}
    </Button>
  );
};

export const CustomSwitch: FC<CustomSwitchProps> = ({ control, fields, className }) => {
  return (
    <span className={className}>
      <Controller name={projectProperties.type.id} control={control} render={({ field }) => <MySwitch field={field} onClick={() => field.onChange(fields.left.value)} label={fields.left.label} />} />
      <Controller name={projectProperties.type.id} control={control} render={({ field }) => <MySwitch field={field} onClick={() => field.onChange(fields.right.value)} label={fields.right.label} />} />
    </span>
  );
};
