import { useForm } from 'react-hook-form';
import { projectProperties } from '../../../../utils/projects';
import { ProjectController } from '../../../../datasource/ProjectController';
import { useProjectsStore } from '../../../../store/ProjectsStore';
import { type NewProject } from '../../../../models/view/NewProject';
import { type ProjectTypeResponse } from '../../../../models/response/ProjectResponse';
import { useNavigate } from 'react-router-dom';

export const useMyForm = (toggleDialog: () => void) => {
  const {
    register: registerField,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      [projectProperties.type.id]: 'child',
    },
  });
  const navigate = useNavigate();

  const addProject = useProjectsStore((store) => store.addProject);

  const myHandleSubmit = handleSubmit(async (data) => {
    try {
      const newProject: NewProject = {
        name: data[projectProperties.name.id],
        NHSProjPartner: data[projectProperties.NHSProjPartner.id],
        NHSProjLead: data[projectProperties.NHSProjLead.id],
        NHSLeadEmail: data[projectProperties.NHSLeadEmail.id],
        type: data[projectProperties.type.id] as ProjectTypeResponse,
      };

      const projectProvider = new ProjectController();
      const createdProject = await projectProvider.createProject(newProject);
      addProject(createdProject);
      toggleDialog();
      navigate(`./projects/${createdProject.id}`);
      // TODO: Give feedback to the user
    } catch (e: any) {
      console.log('[Error] failed creating project');
      // TODO: Handle show error
      toggleDialog();
    }
  });

  return {
    registerField,
    errors,
    handleSubmit: myHandleSubmit,
    control,
  };
};
