import { Question } from '../models/view/Question';
import { CATEGORIES } from './questionnaireCategories';

export const CHILD_QUESTIONNAIRE: Question[] = [
  /**
   * Service organisation
   */
  {
    id: 1,
    text: 'Provide diagnostic, treatment and consultation services for people with ADHD, who have complex needs, or when general psychiatric services are in doubt about the diagnosis or management of ADHD?',
    category: CATEGORIES.serviceOrganisation,
  },
  {
    id: 2,
    text: 'Communicate and share information among paediatric, child and adolescent, forensic and adult mental health services for people with a diagnosis of ADHD, including transition between child and adult services?',
    category: CATEGORIES.serviceOrganisation,
  },
  {
    id: 3,
    text: 'Manage shared care arrangements with primary care providers and ensure clear lines of communication between primary and secondary care are maintained?',
    category: CATEGORIES.serviceOrganisation,
  },
  {
    id: 4,
    text: 'Oversee the implementation of the NICE guidelines?',
    category: CATEGORIES.serviceOrganisation,
  },
  /**
   * Recognition, identification, referral
   */
  {
    id: 5,
    text: 'Recognise which groups may have increased prevalence of ADHD compared to the general population?',
    category: CATEGORIES.recognitionIdentificationReferral,
  },
  {
    id: 6,
    text: 'Build awareness that ADHD is thought to be under-recognised in girls?',
    category: CATEGORIES.recognitionIdentificationReferral,
  },
  {
    id: 7,
    text: 'Enable referral from the community to secondary care, which may involve health, education and social care professionals?',
    category: CATEGORIES.recognitionIdentificationReferral,
  },
  {
    id: 8,
    text:
      "Carry out recommended actions, including watchful waiting for up to 10 weeks or offering referral to group-based, ADHD-focused support (where a child or young person's behavioural and/or attention problems suggestive of ADHD are having an adverse impact on their development or family life)?",
    category: CATEGORIES.recognitionIdentificationReferral,
  },
  /**
   * Diagnosis
   */
  {
    id: 9,
    text: 'Ensure that a diagnosis of ADHD is only made by a specialist psychiatrist, paediatrician or other appropriately qualified HCP with training and expertise in ADHD?',
    category: CATEGORIES.diagnosis,
  },
  {
    id: 10,
    text: 'Ensure that the diagnosis process meets the criteria specified in the NICE guidelines? ',
    category: CATEGORIES.diagnosis,
  },
  {
    id: 11,
    text: 'Follow the diagnostic criteria in DSM-5 or ICD-10?',
    category: CATEGORIES.diagnosis,
  },
  {
    id: 12,
    text: "Take into account an assessment of the person's needs, coexisting condition, and the parents' or carers' mental health?",
    category: CATEGORIES.diagnosis,
  },
  {
    id: 13,
    text: "Take into account an assessment of the person's social, familial and educational or occupational circumstances and physical health?",
    category: CATEGORIES.diagnosis,
  },
  {
    id: 14,
    text: 'Adjust symptom criteria for age-appropriate changes in behaviour?',
    category: CATEGORIES.diagnosis,
  },
  /**
   * Information and support
   */
  {
    id: 15,
    text: 'Provide information and support for people who have had an assessment, but whose symptoms and impairment fall short of a diagnosis of ADHD?',
    category: CATEGORIES.informationAndSupport,
  },
  {
    id: 16,
    text: 'Inform people receiving a diagnosis of ADHD (and their families or carers as appropriate) about local and national sources of support and information?',
    category: CATEGORIES.informationAndSupport,
  },
  {
    id: 17,
    text: 'Provide information to people with ADHD in a form that takes into account factors including their developmental level, cognitive style, emotional maturity and cognitive capacity?',
    category: CATEGORIES.informationAndSupport,
  },
  {
    id: 18,
    text:
      'Provide information for people with ADHD in a form that takes into account factors including any learning disabilities, delays in language development and social communication difficulties, sight or hearing problems and any co-existing neurodevelopmental and mental health conditions?',
    category: CATEGORIES.informationAndSupport,
  },
  {
    id: 19,
    text: 'Encourage family members or carers of people with ADHD to seek an assessment of their personal, social and mental health needs, and to join self-help and support groups, if appropriate?',
    category: CATEGORIES.informationAndSupport,
  },
  {
    id: 20,
    text:
      'Obtain consent to contact schools, colleges and universities to involve them in areas including identified special educational needs, advice for reasonable adjustments and environmental modifications, and feedback needed from education providers?',
    category: CATEGORIES.informationAndSupport,
  },
  {
    id: 21,
    text: 'Take into account the needs of a parent with ADHD who also has a child with ADHD, including if they need extra support with organisational strategies (for example, with adherence to treatment)?',
    category: CATEGORIES.informationAndSupport,
  },
  /**
   * Managing ADHD
   */
  {
    id: 22,
    text: 'Ensure continuity of care for people with ADHD?',
    category: CATEGORIES.managingADHD,
  },
  {
    id: 23,
    text: 'Ensure that people with ADHD have a comprehensive, holistic, shared treatment plan in line with the NICE guideline recommendations? ',
    category: CATEGORIES.managingADHD,
  },
  {
    id: 24,
    text: 'Regularly discuss with people with ADHD, and with consent, their family members or carers, how they want to be involved in treatment planning and decisions?',
    category: CATEGORIES.managingADHD,
  },
  {
    id: 25,
    text: 'Offer an ADHD-focussed group parent training programme to parents or carers of children under 5 years with ADHD as first-line treatment?',
    category: CATEGORIES.managingADHD,
  },
  {
    id: 26,
    text: 'Offer parents and carers of children and young people aged 5 years and over with ADHD-group-based, ADHD-focussed support (may be as few as one or two sessions)?',
    category: CATEGORIES.managingADHD,
  },
  {
    id: 27,
    text:
      'Offer medication for children and young people (within licensed indication) only if their symptoms are having a persistent impact in at least one domain after they and their parents have received ADHD-focussed information, and environmental modifications have been made and a baseline assessment has been carried out.',
    category: CATEGORIES.managingADHD,
  },
  /**
   * Medication
   */
  {
    id: 28,
    text: 'Ensure that all medication for ADHD should only be initiated by a healthcare professional with training and expertise in diagnosing and managing ADHD?',
    category: CATEGORIES.medication,
  },
  {
    id: 29,
    text: 'Ensure that healthcare professionals initiating medication for ADHD tailor treatment effectively to the individual needs of the person?',
    category: CATEGORIES.medication,
  },
  {
    id: 30,
    text: 'Think about modified-release (if suitable}, once-daily preparations when prescribing ADHD stimulants? ',
    category: CATEGORIES.medication,
  },
  {
    id: 31,
    text: 'Carry out a full baseline assessment before starting medication - including a review to confirm people with ADHD continue to meet the criteria for ADHD and need treatment? ',
    category: CATEGORIES.medication,
  },
  {
    id: 32,
    text: "Carry out a full baseline assessment before starting medication -including the patient's physical health, presence of coexisting mental health and neurodevelopmental conditions?",
    category: CATEGORIES.medication,
  },
  {
    id: 33,
    text: 'Follow the NICE guideline recommendations on medication choice for children and young people (within licensed indications)? ',
    category: CATEGORIES.medication,
  },
  {
    id: 34,
    text: 'Follow the NICE guideline recommendations on initiation and titration of ADHD medications? ',
    category: CATEGORIES.medication,
  },
  {
    id: 35,
    text: 'Use standard symptom and adverse effect rating scales for clinical assessment and throughout the course of treatment?',
    category: CATEGORIES.medication,
  },
  {
    id: 36,
    text: 'Build awareness that individuals respond to stimulants in different ways in terms of effect size, duration of effect and adverse effects? ',
    category: CATEGORIES.medication,
  },
  {
    id: 37,
    text: 'Follow the switching criteria and process specified in the NICE guidelines, including 6-week trials at an adequate dose where patients have not derived enough benefit in terms of reduced ADHD symptoms and associated impairment?',
    category: CATEGORIES.medication,
  },
  /**
   * Maintenance and monitoring
   */
  {
    id: 38,
    text: "Monitor effectiveness of medication for ADHD and adverse events, and document in the person's notes?",
    category: CATEGORIES.maintenanceAndMonitoring,
  },
  {
    id: 39,
    text: 'Ensure that people receiving treatment for ADHD have review and follow-up according to the severity of their condition?',
    category: CATEGORIES.maintenanceAndMonitoring,
  },
  {
    id: 40,
    text: 'Follow the NICE guideline recommendations on measuring height every 6 months?',
    category: CATEGORIES.maintenanceAndMonitoring,
  },
  {
    id: 41,
    text: 'Follow the NICE guideline recommendations on measuring weight at the recommended frequency for age and concern?',
    category: CATEGORIES.maintenanceAndMonitoring,
  },
  {
    id: 42,
    text: 'Monitor heart rate and blood pressure, and compare with the normal range for age before and after each dose change and every 6 months?',
    category: CATEGORIES.maintenanceAndMonitoring,
  },
  {
    id: 43,
    text: 'Monitor other factors including behaviour, tics, sleep patterns, seizures, and potential for stimulant misuse and diversion?',
    category: CATEGORIES.maintenanceAndMonitoring,
  },
  {
    id: 44,
    text: 'Ensure awareness that the symptoms of ADHD may lead to people having difficulty to adhere to treatment plans?',
    category: CATEGORIES.maintenanceAndMonitoring,
  },
  {
    id: 45,
    text: 'Encourage parents and carers to oversee ADHD medication for children and young people?',
    category: CATEGORIES.maintenanceAndMonitoring,
  },
  /**
   * Review of medication and discontinuation
   */
  {
    id: 46,
    text: 'Review ADHD medication at least once a year, and discuss with the person with ADHD (and their families and carers as appropriate) if medication should be continued?',
    category: CATEGORIES.reviewOfMedicationAndDiscontinuation,
  },
  {
    id: 47,
    text: 'Carry out reviews that include a comprehensive assessment in line with the criteria recommended in the NICE guidelines? ',
    category: CATEGORIES.reviewOfMedicationAndDiscontinuation,
  },
  {
    id: 48,
    text: 'Encourage people with ADHD to discuss any preferences to stop or change medication, and to be involved in any decisions about stopping treatments?',
    category: CATEGORIES.reviewOfMedicationAndDiscontinuation,
  },
  /**
   * Transition
   */
  {
    id: 49,
    text: 'Re-assess young people receiving treatment and care from CAMHS/ paediatric services at school leaving age (usually by the time the young person is 18 years old)?',
    category: CATEGORIES.transition,
  },
  {
    id: 50,
    text: 'Assess if there is a need for continuing treatment into adulthood?',
    category: CATEGORIES.transition,
  },
  {
    id: 51,
    text: 'Meet and exchange information between CAMHS and/or paediatric services and adult services?',
    category: CATEGORIES.transition,
  },
  {
    id: 52,
    text: 'Provide information about adult services to the young person and, where appropriate the parent or carer, and involve them in planning?',
    category: CATEGORIES.transition,
  },
  {
    id: 53,
    text:
      'Carry out a comprehensive assessment of the person with ADHD after the persons transition to adult services, including functioning, coexisting conditions - especially drug misuse, personality disorders, emotional problems and learning difficulties?',
    category: CATEGORIES.transition,
  },
];
