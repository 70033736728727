import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, GridColumn as Column, GridSelectionChangeEvent, getSelectedState } from '@progress/kendo-react-grid';
import { filterBy, type CompositeFilterDescriptor } from '@progress/kendo-data-query';

import { projectProperties } from '../../../utils/projects';
import { ProjectType, type Project } from '../../../models/view/Project';
import './ProjectsTable.scss';
import { ProjectTypeResponse } from '../../../models/response/ProjectResponse';

export const ProjectsTable: FC<{ projects: Project[]; filter: CompositeFilterDescriptor }> = ({ projects, filter }) => {
  const navigate = useNavigate();
  
  //This variable contains the data that will be passed to the columns, changing the project type to CYP if it is Child
  const columnDatas = (filterBy(projects, filter)).map(prj => ({ ...prj, type: prj.type == ProjectType.Adult ? ProjectType.Adult : 'CYP' }))


  const onClick = (event: GridSelectionChangeEvent) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: {},
      dataItemKey: projectProperties.id.id,
    });
    const projectId = Object.keys(newSelectedState)[0];
    navigate(`/projects/${projectId}`);
  };


  return (
    <Grid className="table" data={columnDatas} onSelectionChange={onClick} selectable={{ enabled: true, drag: false, mode: 'single' }}>
      <Column width={70} field={projectProperties.id.id} title={projectProperties.id.shortTitle} />
      <Column field={projectProperties.name.id} title={projectProperties.name.shortTitle} />
      <Column field={projectProperties.takedaRepr.id} title={projectProperties.takedaRepr.shortTitle} />
      <Column field={projectProperties.NHSProjPartner.id} title={projectProperties.NHSProjPartner.longTitle} />
      <Column field={projectProperties.NHSProjLead.id} title={projectProperties.NHSProjLead.longTitle} />
      <Column width={80} field={projectProperties.type.id} title={projectProperties.type.shortTitle} />
      <Column field={projectProperties.startDate.id} title={projectProperties.startDate.shortTitle} />
    </Grid>
  );
};
