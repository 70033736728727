import { Question } from '../models/view/Question';
import { CATEGORIES } from './questionnaireCategories';

export const ADULT_QUESTIONNAIRE: Question[] = [
  /**
   * Service organisation
   */
  {
    id: 1,
    text: 'Provide diagnostic, treatment and consultation services for people with ADHD, who have complex needs, or when general psychiatric services are in doubt about the diagnosis or management of ADHD?',
    category: CATEGORIES.serviceOrganisation,
  },
  {
    id: 2,
    text: 'Communicate and share information among paediatric, child and adolescent, forensic and adult mental health services for people with a diagnosis of ADHD, including transition between child and adult services?',
    category: CATEGORIES.serviceOrganisation,
  },
  {
    id: 3,
    text: 'Manage shared care arrangements with primary care providers and ensure clear lines of communication between primary and secondary care are maintained?',
    category: CATEGORIES.serviceOrganisation,
  },
  {
    id: 4,
    text: 'Oversee the implementation of the NICE guidelines?',
    category: CATEGORIES.serviceOrganisation,
  },
  /**
   * Recognition, identification, referral
   */
  {
    id: 5,
    text: 'Recognise which groups may have increased prevalence of ADHD compared to the general population?',
    category: CATEGORIES.recognitionIdentificationReferral,
  },
  {
    id: 6,
    text: 'Build awareness that ADHD is thought to be under-recognised in women?',
    category: CATEGORIES.recognitionIdentificationReferral,
  },
  {
    id: 7,
    text: 'Enable assessment of adults presenting with symptoms of ADHD in primary care or general adult psychiatric services, who do not have a childhood diagnosis of ADHD?',
    category: CATEGORIES.recognitionIdentificationReferral,
  },
  {
    id: 8,
    text: 'Enable assessment of adults who have previously been treated for ADHD as a child or young person and present with symptoms suggestive of continuing ADHD?',
    category: CATEGORIES.recognitionIdentificationReferral,
  },
  /**
   * Diagnosis
   */
  {
    id: 9,
    text: 'Ensure that a diagnosis of ADHD is only made by a specialist psychiatrist, paediatrician or other appropriately qualified HCP with training and expertise in ADHD?',
    category: CATEGORIES.diagnosis,
  },
  {
    id: 10,
    text: 'Ensure that the diagnostic process meets the criteria specified in the NICE guidelines?',
    category: CATEGORIES.diagnosis,
  },
  {
    id: 11,
    text: 'Follow the diagnostic criteria in DSM-5 or ICD-10?',
    category: CATEGORIES.diagnosis,
  },
  {
    id: 12,
    text: "Take into account an assessment of the person's needs, coexisting condition, and the parents' or carers' mental health?",
    category: CATEGORIES.diagnosis,
  },
  {
    id: 13,
    text: "Take into account an assessment of the person's social, familial and educational or occupational circumstances and physical health?",
    category: CATEGORIES.diagnosis,
  },
  {
    id: 14,
    text: 'Conduct a full clinical and psychosocial assessment of the person, their full developmental and psychiatric history, and observer reports and assessment of the person’s mental state?',
    category: CATEGORIES.diagnosis,
  },
  /**
   * Information and support
   */
  {
    id: 15,
    text: 'Provide information and support for people who have had an assessment, but whose symptoms and impairment fall short of a diagnosis of ADHD?',
    category: CATEGORIES.informationAndSupport,
  },
  {
    id: 16,
    text: 'Inform people receiving a diagnosis of ADHD (and their families or carers) as appropriate about local and national sources of support and information?',
    category: CATEGORIES.informationAndSupport,
  },
  {
    id: 17,
    text: 'Provide information to people with ADHD in a form that takes into account factors including their developmental level, cognitive style, emotional maturity and cognitive capacity?',
    category: CATEGORIES.informationAndSupport,
  },
  {
    id: 18,
    text:
      'Provide information for people with ADHD in a form that takes into account factors including any learning disabilities, delays in language development and social communication difficulties, sight or hearing problems and any co-existing neurodevelopmental and mental health conditions? ',
    category: CATEGORIES.informationAndSupport,
  },
  {
    id: 19,
    text: 'Offer advice to families and carers of adults with ADHD, including about how ADHD can affect relationships and functioning?',
    category: CATEGORIES.informationAndSupport,
  },
  {
    id: 20,
    text: 'Involve other healthcare professionals, when a person with ADHD and coexisting conditions gives consent?',
    category: CATEGORIES.informationAndSupport,
  },
  {
    id: 21,
    text: 'Take into account the needs of a parent with ADHD who also has a child with ADHD, including if they need extra support with organisational strategies (for example, with adherence to treatment)?',
    category: CATEGORIES.informationAndSupport,
  },
  /**
   * Managing ADHD
   */
  {
    id: 22,
    text: 'Ensure continuity of care for people with ADHD?',
    category: CATEGORIES.managingADHD,
  },
  {
    id: 23,
    text: 'Ensure that people with ADHD have a comprehensive, holistic, shared treatment plan in line with the NICE guideline recommendations?',
    category: CATEGORIES.managingADHD,
  },
  {
    id: 24,
    text: 'Regularly discuss with people with ADHD, and with consent, their family members or carers, how they want to be involved in treatment planning and decisions?',
    category: CATEGORIES.managingADHD,
  },
  {
    id: 25,
    text: 'Consider non-pharmacological treatment for adults who have made an informed choice not to have medication, difficulty adhering to medication, found medication to be ineffective, or cannot tolerate it?',
    category: CATEGORIES.managingADHD,
  },
  {
    id: 26,
    text: 'Offer medication to adults with ADHD if their symptoms are causing significant impairment in at least one domain of their everyday life after environmental modifications?',
    category: CATEGORIES.managingADHD,
  },
  /**
   * Medication
   */
  {
    id: 27,
    text: 'Ensure that all medication for ADHD should only be initiated by a healthcare professional with training and expertise in diagnosing and managing ADHD?',
    category: CATEGORIES.medication,
  },
  {
    id: 28,
    text: 'Ensure that healthcare professionals initiating medication for ADHD tailor treatment effectively to the individual needs of the person?',
    category: CATEGORIES.medication,
  },
  {
    id: 29,
    text: 'Think about modified-release (if suitable}, once-daily preparations when prescribing ADHD stimulants? ',
    category: CATEGORIES.medication,
  },
  {
    id: 30,
    text: 'Carry out a full baseline assessment before starting medication -including a review to confirm people with ADHD continue to meet the criteria for ADHD and need treatment? ',
    category: CATEGORIES.medication,
  },
  {
    id: 31,
    text: "Carry out a full baseline assessment before starting medication -including the patient's physical health, presence of coexisting mental health and neurodevelopmental conditions?",
    category: CATEGORIES.medication,
  },
  {
    id: 32,
    text: 'Follow the NICE guideline recommendations on medication choice for adults? ',
    category: CATEGORIES.medication,
  },
  {
    id: 33,
    text: 'Follow the NICE guideline recommendations on initiation and titration of ADHD medications? ',
    category: CATEGORIES.medication,
  },
  {
    id: 34,
    text: 'Use standard symptom and adverse effect rating scales for clinical assessment and throughout the course of treatment?',
    category: CATEGORIES.medication,
  },
  {
    id: 35,
    text: 'Build awareness that individuals respond to stimulants in different ways in terms of effect size, duration of effect and adverse effects? ',
    category: CATEGORIES.medication,
  },
  {
    id: 36,
    text: 'Follow the switching criteria and process specified in the NICE guidelines, including 6-week trials at an adequate dose where patients have not derived enough benefit in terms of reduced ADHD symptoms and associated impairment? ',
    category: CATEGORIES.medication,
  },
  /**
   * Maintenance and monitoring
   */
  {
    id: 37,
    text: "Monitor effectiveness of medication for ADHD and adverse events, and document in the person's notes? ",
    category: CATEGORIES.maintenanceAndMonitoring,
  },
  {
    id: 38,
    text: 'Ensure that people receiving treatment for ADHD have review and follow-up according to the severity of their condition?',
    category: CATEGORIES.maintenanceAndMonitoring,
  },
  {
    id: 39,
    text: 'Monitor heart rate and blood pressure, and compare with the normal range for age before and after each dose change and every 6 months? ',
    category: CATEGORIES.maintenanceAndMonitoring,
  },
  {
    id: 40,
    text: 'Monitor Body Mass Index if the adult has experienced a weight change as a result of their treatment?',
    category: CATEGORIES.maintenanceAndMonitoring,
  },
  {
    id: 41,
    text: 'Monitor other factors including behaviour, tics, sleep patterns, seizures, and potential for stimulant misuse and diversion?',
    category: CATEGORIES.maintenanceAndMonitoring,
  },
  {
    id: 42,
    text: 'Ensure awareness that the symptoms of ADHD may lead to people having difficulty to adhere to treatment plans?',
    category: CATEGORIES.maintenanceAndMonitoring,
  },
  {
    id: 43,
    text: 'Encourage the person with ADHD to use the strategies recommended in the NICE guidelines to support adherence to treatment (pharmacological and non-pharmacological)? ',
    category: CATEGORIES.maintenanceAndMonitoring,
  },
  /**
   * Review of medication and discontinuation
   */
  {
    id: 44,
    text: 'Review ADHD medication at least once a year, and discuss with the person with ADHD (and their families and carers as appropriate) if medication should be continued?',
    category: CATEGORIES.reviewOfMedicationAndDiscontinuation,
  },
  {
    id: 45,
    text: 'Carry out reviews that include a comprehensive assessment in line with the criteria recommended in the NICE guidelines? ',
    category: CATEGORIES.reviewOfMedicationAndDiscontinuation,
  },
  {
    id: 46,
    text: 'Encourage people with ADHD to discuss any preferences to stop or change medication, and to be involved in any decisions about stopping treatments?',
    category: CATEGORIES.reviewOfMedicationAndDiscontinuation,
  },
  /**
   * Transition
   */
  {
    id: 47,
    text: 'Re-assess young people receiving treatment and care from CAMHS/ paediatric services at school leaving age (usually by the time the young person is 18 years old)?',
    category: CATEGORIES.transition,
  },
  {
    id: 48,
    text: 'Assess if there is a need for continuing treatment into adulthood?',
    category: CATEGORIES.transition,
  },
  {
    id: 49,
    text: 'Meet and exchange information between CAMHS and/or paediatric services and adult services?',
    category: CATEGORIES.transition,
  },
  {
    id: 50,
    text: 'Provide information about adult services to the young person and, where appropriate the parent or carer, and involve them in planning?',
    category: CATEGORIES.transition,
  },
  {
    id: 51,
    text:
      'Carry out a comprehensive assessment of the person with ADHD after the persons transition to adult services, including functioning, coexisting conditions - especially drug misuse, personality disorders, emotional problems and learning difficulties?',
    category: CATEGORIES.transition,
  },
];
