import { FC } from 'react';

import { InfoTagProps } from './model/InfoTagProps';
import './InfoTag.scss';

export const InfoTag: FC<InfoTagProps> = ({ label, value }) => {
  return (
    <div className="info-tag__container">
      <span className="info-tag__label">{label}</span>
      <span className="info-tag__value">{value}</span>
    </div>
  );
};
