import { Button } from "@progress/kendo-react-buttons";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./SurveyButtons.scss";

const SurveyButtons = ({
  selectedIdsStore,
}: {
  selectedIdsStore: number[];
}) => {
  const navigate = useNavigate();
  const projectId = useParams().projectId;
  const [selectedButton, setSelectedButton] = useState<number | null>(
    selectedIdsStore.length > 0 ? selectedIdsStore[0] : null
  );
  const [buttonWidth, setButtonWidth] = useState<number>(60);
  const [fontSize, setFontSize] = useState<number>(13);

  const handleClick = (benchmarkId: number) => {
    if (selectedButton !== benchmarkId) {
      setSelectedButton(benchmarkId);
      navigate(`/projects/${projectId}/create-report/${benchmarkId}`, {
        replace: true,
      });
    }
  };

  useEffect(() => {
    // Update selectedButton when selectedIdsStore changes to ensure the first button is selected if available
    if (selectedIdsStore.length > 0 && selectedButton !== selectedIdsStore[0]) {
      setSelectedButton(selectedIdsStore[0]);
    }

    // Update button width and font size based on screen width
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const maxButtonsHorizontal = 3; // Maximum buttons in horizontal layout
      const maxButtonsVertical = 5; // Maximum buttons in vertical layout
      const maxButtonWidth = screenWidth / maxButtonsHorizontal;
      const minButtonWidth = screenWidth / maxButtonsVertical;
      const newButtonWidth = Math.min(maxButtonWidth, minButtonWidth);
      setButtonWidth(newButtonWidth);

      // Adjust font size based on button width
      const newFontSize = Math.min(14, Math.floor(newButtonWidth / 6)); // Adjust font size based on button width
      setFontSize(newFontSize);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [selectedIdsStore, setSelectedButton, buttonWidth, fontSize]);

  return (
    <div className="survey-buttons-container">
      <div className="survey-buttons__container">
        <p className="survey-buttons-title">Select Benchmark:</p>
        <div className="survey-buttons-container__content">
          {selectedIdsStore?.map((benchmarkId, index) => (
            <Button
              key={benchmarkId}
              className="survey__button"
              style={{
                background:
                  selectedButton === benchmarkId ? "#0D6EFD" : "white",
                color: selectedButton === benchmarkId ? "white" : "#495057",
                ...(selectedIdsStore.length >= 10 && window.innerWidth < 1300
                  ? {
                      maxWidth: `${buttonWidth}px`,
                      fontSize: `${fontSize}px`,
                    }
                  : { width: `70px`, fontSize: `15px` }),
                ...(selectedIdsStore.length >= 10 &&
                  window.innerWidth < 780 && {
                    width: `70px`,
                    fontSize: `15px`,
                  }),
              }}
              fillMode={null}
              onClick={() => handleClick(benchmarkId)}
            >
              ID# {benchmarkId}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SurveyButtons;
