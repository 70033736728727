import { Outlet } from 'react-router-dom';

import { Header } from '../components/header/Header';
import { useAuthVerify } from './hooks/useAuthVerify';
import { useShowDialog } from '../hooks/useShowDialog';
import { CreateProjectDialog } from '../components/createprojectdialog/CreateProjectDialog';
import './MainLayout.scss';

export const MainLayout = () => {
  const { isAuthenticated, username, locationPath } = useAuthVerify();
  const headerText = `${username}, Takeda SDM`;
  const showCreateProjectButton = locationPath === '/';

  // Create Project Dialog
  const { visible: isCreateProjectDialogVisible, toggleDialog: toggleCreateProjectDialog } = useShowDialog();

  return (
    <>
      {isAuthenticated ? (
        <div>
          <Header headerText={headerText} showCreateProjectButton={showCreateProjectButton} handleClick={toggleCreateProjectDialog} />
          <main className="main-container">
            <Outlet />
          </main>
        </div>
      ) : (
        <main>
          <Outlet />
        </main>
      )}
      {isCreateProjectDialogVisible && <CreateProjectDialog toggleDialog={toggleCreateProjectDialog} />}
    </>
  );
};
