import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useProjectDetailsStore } from "../../../../store/ProjectDetailsStore";
import {
  Questionnaire,
  QuestionnaireAdult,
  QuestionnaireChild,
} from "../../../../utils/questionnaires";
import { ProjectType } from "../../../../models/view/Project";

export const useBenchmarkIdsArray = (benchmarkIds: number[]) => {
  const navigate = useNavigate();
  const projectId = useParams().projectId!!;
  const [project, benchmarks, loading] = useProjectDetailsStore((store) => [
    store.project,
    store.benchmarks,
    store.loading,
  ]);
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>();
  const benchmarkId = benchmarkIds[0]

  useEffect(() => {
    if (project != null && benchmarks.length > 0) {
      const filteredBenchmarks = benchmarks.filter((item) =>
        benchmarkIds.includes(item.id)
      );
      let questionnaire: Questionnaire;

      if (project?.type === ProjectType.Adult) {
        questionnaire = new QuestionnaireAdult();
      } else {
        questionnaire = new QuestionnaireChild();
      }

      for (let benchmark of filteredBenchmarks) {
        for (let answers of benchmark.answers) {
          questionnaire.setAnswersArraybyQuestionId(
            Number(answers.questionId),
            answers.answer
          );
        }
      }

      setQuestionnaire(questionnaire);
    } else {
      if (!loading) {
        navigate(`/projects/${projectId}`);
      }
    }
  }, [project, benchmarkIds, benchmarks, projectId, loading, navigate]);

  return { questionnaire, benchmarkId };
};
